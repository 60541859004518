import React from 'react';
import styles from './fpGateContent.less';
import { useTranslation } from 'react-i18next';
import Supplier from '../../../assets/images/png/supplier.png';
import Crate from '../../../assets/images/png/crate.png';
import Certificate from '../../../assets/images/png/certificate.png';
import Security from '../../../assets/images/png/security.png';
import Compass from '../../../assets/images/png/compass.png';
import People from '../../../assets/images/png/people.png';
import Linux from '../../../assets/images/png/linux.png';
import MariaDb from '../../../assets/images/png/mariaDb.png';
import php from '../../../assets/images/png/php.png';
import Apache from '../../../assets/images/png/apache.png';

export const FpGateContent = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.fpGateInfo}>
                <h1 className={styles.heading}>{t('FP Gate Payment Gateway')}</h1>
                <p className={styles.text}>
                    {t('Processing center FP Gate')}
                    <span>
                        {t(
                            ' - a powerful, modern, scalable and quickly integrated payment platform that provides organization of payment acceptance using various payment instruments for banks, mobile operators, payment systems and other commercial organizations.',
                        )}
                    </span>
                </p>
            </div>
            <div className={styles.fpGateFeatures}>
                <h1 className={styles.fpGateFeaturesHeading}>{t('What can fp gate do?')}</h1>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        <img src={Supplier} alt="Supplier" className={styles.fpGateImg} />
                        <h2 className={styles.mainText}>{t('Fast connection of merchants and service providers')}</h2>
                        <p className={styles.secondaryText}>{t('It is possible to adapt the input API to the requirements')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={Crate} alt="Crate" className={styles.fpGateImg} />
                        <h2 className={styles.mainText}>
                            {t('High performance and accurate scaling of the infrastructure for the tasks being solved')}
                        </h2>
                        <p className={styles.secondaryText}>{t('')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={Certificate} alt="Certificate" className={styles.fpGateImg} />
                        <h2 className={styles.mainText}>{t('No problems with software licensing and dependency on third parties')}</h2>
                        <p className={styles.secondaryText}>{t('The system is developed on the basis of free publicly available technologies')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={Security} alt="Security" className={styles.fpGateImg} />
                        <h2 className={styles.mainText}>
                            {t('High level of payment security – processing is certified according to PCI DSS level 1')}
                        </h2>
                    </li>
                    <li className={styles.listItem}>
                        <img src={Compass} alt="Compass" className={styles.fpGateImg} />
                        <h2 className={styles.mainText}>{t('High level of fault tolerance due to distributed architecture')}</h2>
                    </li>
                    <li className={styles.listItem}>
                        <img src={People} alt="People" className={styles.fpGateImg} />
                        <h2 className={styles.mainText}>{t('Possibility of customization for individual customer requirements')}</h2>
                    </li>
                </ul>
            </div>
            <div className={styles.tech}>
                <h1 className={styles.techHeading}>{t('Technology stack')}</h1>
                <ul className={styles.stackList}>
                    <li className={styles.stackListItem}>
                        <img src={Linux} alt="Linux" className={styles.stackImg} />
                    </li>
                    <li className={styles.stackListItem}>
                        <img src={Apache} alt="Apache" className={styles.stackImg} />
                    </li>
                    <li className={styles.stackListItem}>
                        <img src={MariaDb} alt="MariaDb" className={styles.stackImg} />
                    </li>
                    <li className={styles.stackListItem}>
                        <img src={php} alt="php" className={styles.stackImg} />
                    </li>
                </ul>
            </div>
        </div>
    );
};
