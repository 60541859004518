import styles from './contactsMap.less';
import { useTranslation } from 'react-i18next';
import office from '../../../assets/images/png/office.png';
import map from '../../../assets/images/jpg/map.jpg';
import { Link } from 'react-router-dom';

const yandexMapHref = 'https://yandex.ru/maps/-/CCU9mSdgtD';

export const ContactsMap = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.blurryTopFrame}></div>
            <div className={styles.blurredFrame}>
                <h1 className={styles.mainText}>{t('CONTACTS')}</h1>
                <p className={styles.secondaryText}>
                    {t('119021, Moscow, Olsufevsky lane, building No. 8, building 3, attic floor, room I, room 16')}
                </p>
                <p className={styles.secondaryText}>
                    <a href="tel:+74951280662">+7 (495) 128-06-62</a>
                </p>
                <p className={styles.secondaryText}>
                    <a href="mailto:info@forvard.me">info@forvard.me</a>
                </p>
            </div>

            <div className={styles.mapContainer}>
                <Link to={yandexMapHref} target="_blank">
                    <div className={styles.map}>
                        <img src={map} alt="map" className={styles.mapImg} />
                    </div>
                    <div className={styles.imgWrapper}>
                        <img src={office} alt="office" />
                    </div>
                </Link>
            </div>
        </div>
    );
};
