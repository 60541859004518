import React from 'react';
import styles from './carousel.less';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { carouselListData } from './carousel.helper';
import { CarouselCard } from '../carouselCard/carouselCard';
import nextArrow from '../../../assets/images/png/NextArrow.png';
import prevArrow from '../../../assets/images/png/PrevArrow.png';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className} src={nextArrow} style={{ ...style, width: '82px', height: '82px', zIndex: '1' }} onClick={onClick} alt="next" />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className} src={prevArrow} style={{ ...style, width: '82px', height: '82px', zIndex: '1' }} onClick={onClick} alt="prev" />
    );
}

export const Carousel = () => {
    const { t } = useTranslation();

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.mainText}>{t('We are trusted')}</h1>
            <Slider {...settings} className={styles.carousel}>
                {carouselListData.map((el, idx) => {
                    return <CarouselCard {...el} key={idx} />;
                })}
            </Slider>
        </div>
    );
};
