import { Button } from '../button/button';
import styles from './mainHeading.less';
import { useTranslation } from 'react-i18next';
import eyeFirst from '../../../assets/images/png/eye1.png';
import eyeSecond from '../../../assets/images/png/eye2.png';
import cn from 'classnames';

export const MainHeading = ({ scrollToForm }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.container}>
                <img className={cn(styles.eyeFirst, styles.eye)} src={eyeFirst} alt="eye" />
                <img className={cn(styles.eyeSecond, styles.eye)} src={eyeSecond} alt="eye" />
                <div className={styles.blurredFrame}>
                    <h1 className={styles.mainText}>{t('Software Development')}</h1>
                    <p className={styles.secondaryText}>{t('Implementation of fintech solutions')}</p>
                </div>
                <div className={styles.btnContainer} onClick={scrollToForm}>
                    <Button variant="main">{t('Order a project')}</Button>
                </div>
            </div>
        </>
    );
};
