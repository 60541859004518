import React from 'react';

import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import { TechSupportContent } from '../../components/techSupportContent/techSupportContent';

export const TechSupport = () => {
    return (
        <div>
            <section>
                <TechSupportContent />
                <ApplicationForm variant="hands" />
            </section>
        </div>
    );
};
