import cn from 'classnames';
import defaultStyles from './portfolioCard.less';
import mobileStyles from './portfolioCard.mobile.less';
import { useTranslation } from 'react-i18next';
import arrowFooter from '../../../assets/images/svg/arrowFooter.svg';
import { useMobileWidth } from '../../utils/custom-hooks';
import { useMemo, useState } from 'react';

export const PortfolioCard = ({ variant, text, title, img, openModal, hasDetails, modalText, mobileImg, ignoreMobile }) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);
    const mobileWidth = useMobileWidth();
    const handleClick = () => openModal({ modalText, title, img });

    const showMobileText = () => {
        setShowInfo((prev) => !prev);
    };

    const isMobile = mobileWidth && !ignoreMobile;

    const styles = useMemo(() => {
        return isMobile ? mobileStyles : defaultStyles;
    }, [mobileWidth, ignoreMobile]);

    return (
        <div className={cn(styles.card, !isMobile && styles[variant], showInfo && styles.adaptive)} onClick={isMobile ? showMobileText : undefined}>
            <div className={cn(styles.imgWrapper)}>
                <img src={isMobile ? mobileImg : img} alt="logo" className={cn(showInfo && styles.mobileImg)} />
            </div>
            <div className={styles.textWrapper}>
                <h2 className={styles.title}>{t(title)}</h2>
                <p className={cn(styles.text, styles[variant])}>{t(text)}</p>
                {!showInfo && <img src={arrowFooter} alt="arrowFooter" className={styles.arrowIcon} />}
                {hasDetails && (
                    <button onClick={handleClick} className={styles.detailsBtn}>
                        {t('More')}
                    </button>
                )}
                {showInfo && (
                    <div className={styles.mobileTextBlock}>
                        <h2 className={styles.mobileTitle}>{t(title)}</h2>
                        <p className={cn(styles.mobileText)}>
                            {t(text)}
                            {t(modalText)}
                        </p>
                        <img src={arrowFooter} alt="arrow" className={styles.arrowIconBack} />
                    </div>
                )}
            </div>
        </div>
    );
};
