import React from 'react';
import s from './orderCode.less';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import { useTranslation } from 'react-i18next';

export const OrderCode = () => {
    const { t } = useTranslation();
    return (
        <section className={s.orderWrapper}>
            <div className={s.block1}>
                <div className={s.textBlock1}>
                    <h3>{t('Custom development')} -</h3>
                    <p className={s.text}>
                        {t(
                            'is the process of developing custom software for a customer. That is, every solution, every feature and every aspect of the software is designed to meet the specific needs and requirements of the customer',
                        )}
                    </p>

                    <h4>{t('Why is it beneficial?')}</h4>

                    <div className={s.list}>
                        <div className={s.listItem}>
                            <span className={s.listHead}>{t('Individual requirements')} -</span>
                            {t('ensures that the software will fit perfectly to the specific needs and requirements of the customer')}
                        </div>
                        <div className={s.listItem}>
                            <span className={s.listHead}>{t('Flexibility')} -</span>
                            {t(
                                'you can make changes to the development process and to the final product throughout the process. This ensures that the final product will meet all customer requirements',
                            )}
                        </div>
                        <div className={s.listItem}>
                            <span className={s.listHead}>{t('Quality control')} -</span>
                            {t(
                                'the customer controls the entire development process and can make adjustments at any stage. This will help ensure the high quality of the final product',
                            )}
                        </div>
                        <div className={s.listItem}>
                            <span className={s.listHead}>{t('Uniqueness')} -</span>
                            {t(
                                "as a rule, ready-made software products cannot satisfy all the needs of the customer. Custom software development ensures that the final product will be unique and meet the customer's requirements",
                            )}
                        </div>
                    </div>
                </div>
                <div className={s.textBlock2}>
                    <div className={s.list}>
                        <div className={s.listItem}>
                            <span className={s.listHead}>{t('Support and Updates')} -</span>
                            {t(
                                'the customer receives support and updates for his software immediately from the development team, which will help to solve any problems quickly and efficiently',
                            )}
                        </div>
                        <div className={s.listItem}>
                            <span className={s.listHead}>{t('Saving time and money')} -</span>
                            {t(
                                'software development from scratch may seem like an expensive task, but the customer pays only for what he needs. Thus, it will prove to be more cost-effective than buying an off-the-shelf solution that can be overwhelmed with unnecessary features',
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.block2}>
                <h3>{t('We offer')}</h3>

                <div className={s.cardsPlace}>
                    <div className={s.card}>
                        <div className={s.cardContent}>
                            <div className={s.title}>{t('Corporate and web portals, personal accounts')}</div>
                            <p>
                                {t(
                                    'We create individual corporate and web portals that allow you to manage information, communicate and collaborate within the company, as well as provide access to information and services for external users. We also develop personal accounts that simplify the process of interaction between customers and the company.',
                                )}
                            </p>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.cardContent}>
                            <div className={s.title}>{t('Mobile applications')}</div>
                            <p>
                                {t(
                                    'We develop mobile applications on iOS and Android platforms that meet all customer requirements. We create applications of any complexity, from simple informational to complex services that include working with databases, integration with third-party services, etc.',
                                )}
                            </p>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.cardContent}>
                            <div className={s.title}>{t('Integration of high-load services')}</div>
                            <p>
                                {t(
                                    'We specialize in the integration of complex systems and services for the financial sector, insurance and technology startups. We have experience working with large volumes of data',
                                )}
                            </p>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.cardContent}>
                            <div className={s.title}>{t('Business Process Optimization Software')}</div>
                            <p>
                                {t(
                                    "We create software that helps to optimize the customer's business processes. We develop CRM systems, project management systems, warehouse management systems and much more.",
                                )}
                            </p>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.cardContent}>
                            <div className={s.title}>{t('Software reengineering')}</div>
                            <p>
                                {t(
                                    'We help our clients update outdated software or transfer it to new technologies. We analyze existing software and offer optimal reengineering options that allow for more efficient and reliable system operation.',
                                )}
                            </p>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.cardContent}>
                            <div className={s.title}>{t('UI/UX development')}</div>
                            <p>
                                {t(
                                    'We create a unique design that meets all customer requirements and improves user experience. We analyze competitors, identify the wishes of customers and create a design that stands out in the market.',
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.block3}>
                <h3>{t('Work process')}</h3>

                <div className={s.columnsContent}>
                    <div className={s.first}>
                        <div className={s.column1}>
                            <h5>{t('PROJECT EVALUATION')}</h5>
                            <p>
                                {t(
                                    'At this stage, the idea is discussed, the necessary information about the desired functionality is collected, the work is planned and a commercial proposal is formed with a preliminary assessment of the project.',
                                )}
                            </p>
                        </div>

                        <div className={s.column2}>
                            <h5>{t('PROJECT IMPLEMENTATION')}</h5>
                            <p>
                                {t(
                                    'At this stage, the development environment is prepared and the program code is created in accordance with the approved terms of reference. After the code is written, the software is tested.',
                                )}
                            </p>
                        </div>
                    </div>

                    <div className={s.second}>
                        <div className={s.circleNumber}>1</div>
                        <div className={s.circleNumber}>2</div>
                        <div className={s.circleNumber}>3</div>
                        <div className={s.circleNumber}>4</div>
                    </div>

                    <div className={s.last}>
                        <div className={s.aside1}>
                            <h5>{t('Preparation of terms of reference')}</h5>
                            <p>
                                {t(
                                    'The customer provides data on his business processes, on the basis of which the terms of reference are prepared. In this document, the functionality of the program is prescribed, a prototype is drawn up and other technical details are agreed.',
                                )}
                            </p>
                        </div>
                        <div className={s.aside2}>
                            <h5>{t('Implementation and support')}</h5>
                            <p>
                                {t(
                                    "After testing, the software product is implemented into the customer's infrastructure. Documentation for the software is transferred, the rights to use it, an agreement for warranty support is concluded. In addition, the customer receives advice on the work and development of the project.",
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.block5}>
                <ApplicationForm variant="orderCode" />
            </div>
        </section>
    );
};
