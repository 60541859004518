import styles from './carouselCard.less';
import { useState } from 'react';
import cn from 'classnames';

export const CarouselCard = ({ company, blackImg, colorImg, variant }) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <div className={cn(styles.container, styles[variant])} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            {isHovering ? (
                <div className={styles.companies}>
                    {colorImg.map((el, idx) => {
                        return (
                            <div className={styles.imgWrapper} key={idx}>
                                <img src={el} alt={company} />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={styles.companies}>
                    {blackImg.map((el, idx) => {
                        return (
                            <div className={styles.imgWrapper} key={idx}>
                                <img src={el} alt={company} />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
