import React from 'react';
import { FpGateContent } from '../../components/fpGateContent/fpGateContent';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';

export const FpGate = () => {
    return (
        <div>
            <section>
                <FpGateContent />
                <ApplicationForm variant="gpay" />
            </section>
        </div>
    );
};
