import styles from './partners.less';
import { useTranslation } from 'react-i18next';
import fns from '../../../assets/images/png/fns.png';
import tinek from '../../../assets/images/png/tinek.png';

export const Partners = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{t('Project partners')}</h1>
            <div className={styles.partnersWrapper}>
                <div className={styles.partner}>
                    <img src={fns} alt="logo" className={styles.imageFns} />
                    <p className={styles.text}>{t('Status of an accredited operator of the Federal Tax Service of Russia')}</p>
                </div>
                <div className={styles.partner}>
                    <img src={tinek} alt="logo" className={styles.imageTinkoff} />
                    <p className={styles.text}>{t('Bank-partner for automation and payment security guarantees')}</p>
                </div>
            </div>
        </div>
    );
};
