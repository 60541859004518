import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/images/png/logo_header.png';
import Logo_eng from '../../../assets/images/svg/logo_eng.svg';
import defaultStyles from './footerStyles.less';
import mobileStyles from './footerMobileStyles.less';
import { NavLink, useLocation } from 'react-router-dom';
import { PATHS } from '../../app';
import { useMobileWidth } from '../../utils/custom-hooks';
import classNames from 'classnames';
import chevron from '../../../assets/images/svg/chevronFooter.svg';
import arrowFooter from '../../../assets/images/svg/arrowFooter.svg';
import { ignoreMobileLocations } from '../../utils/common-helper';

const SUB_NAMES = {
    products: 'products',
    services: 'services',
};

export const Footer = () => {
    const { i18n, t } = useTranslation();

    const location = useLocation();
    const [subOpenName, setSubOpenName] = useState(null);
    const toggleSubMenu = (name) => {
        const openSubMenuName = name === subOpenName ? null : name;
        setSubOpenName(openSubMenuName);
    };

    const ignoreMobile = ignoreMobileLocations.includes(location.pathname);
    const mobileWidth = useMobileWidth() && !ignoreMobile;

    const styles = useMemo(() => {
        return ignoreMobile ? defaultStyles : mobileStyles;
    }, [location]);

    const closeMenu = (e) => {
        setSubOpenName(null);
    };
    console.log(i18n.language);
    return (
        <div className={styles.footer}>
            {mobileWidth && (
                <div className={styles.footerFrame}>
                    <div className={styles.footerLeftPart}>
                        {i18n.language === 'ru' && <img src={Logo} alt={t('Forvard')} width={109} height={60} />}
                        {i18n.language === 'en' && <img src={Logo_eng} alt={t('Forvard')} width={109} height={60} />}
                        <div className={styles.contanctsBlock}>
                            <div className={styles.footerPhone}>+7(495)128-06-62</div>
                            <div className={styles.footerMail}>info@forvard.me</div>
                        </div>
                    </div>
                    <div className={styles.footerCenterPart}>
                        <div className={styles.footerCaption}>
                            <div
                                className={styles.ddTop}
                                onClick={() => {
                                    toggleSubMenu(SUB_NAMES.services);
                                }}
                            >
                                <p className={styles.footerCaptionHead}>{t('SERVICES')}</p>
                                <img
                                    src={chevron}
                                    alt="chevron"
                                    className={classNames(styles.footerIcon, subOpenName === SUB_NAMES.services && styles.active)}
                                />
                            </div>

                            {subOpenName === SUB_NAMES.services && (
                                <div className={styles.dd}>
                                    <NavLink className={styles.footerLine} to={PATHS.orderCode}>
                                        {t('Custom development')}
                                    </NavLink>
                                    <NavLink className={styles.footerLine} to={PATHS.consulting}>
                                        {t('Consulting services')}
                                    </NavLink>
                                    <NavLink className={styles.footerLine} to={PATHS.techSupport}>
                                        {t('Technical support')}
                                    </NavLink>
                                </div>
                            )}
                        </div>
                        <div className={styles.footerCaption}>
                            <div
                                className={styles.ddTop}
                                onClick={() => {
                                    toggleSubMenu(SUB_NAMES.products);
                                }}
                            >
                                <p className={styles.footerCaptionHead}>{t('PRODUCTS')}</p>
                                <img
                                    src={chevron}
                                    alt="chevron"
                                    className={classNames(styles.footerIcon, subOpenName === SUB_NAMES.products && styles.active)}
                                />
                            </div>
                            {subOpenName === SUB_NAMES.products && (
                                <div className={styles.dd}>
                                    <NavLink className={styles.footerLine} to={PATHS.fpGate}>
                                        {t('FP Gate Payment Gateway')}
                                    </NavLink>
                                    <NavLink className={styles.footerLine} to={PATHS.topwise}>
                                        {t('POS-terminal TOPWISE')}
                                    </NavLink>
                                    <NavLink className={styles.footerLine} to={PATHS.soyuz}>
                                        {t('“Soyuz” mail aggregator')}
                                    </NavLink>
                                    <NavLink className={styles.footerLine} to={PATHS.acquiring}>
                                        {t('Merchant acquiring')}
                                    </NavLink>
                                    <NavLink className={styles.footerLine} to={PATHS.internetAcquiring}>
                                        {t('Internet acquiring')}
                                    </NavLink>
                                </div>
                            )}
                        </div>
                        <div className={styles.footerCaption}>
                            <NavLink className={styles.singleLink} to={PATHS.portfolio}>
                                {t('PORTFOLIO')}
                            </NavLink>
                            <img src={arrowFooter} alt="arrowFooter" className={styles.footerIcon} />
                        </div>
                        <div className={styles.footerCaption}>
                            <NavLink className={styles.singleLink} to={PATHS.about}>
                                {t('ABOUT COMPANY')}
                            </NavLink>
                            <img src={arrowFooter} alt="arrowFooter" className={styles.footerIcon} />
                        </div>
                        <div className={styles.footerCaption}>
                            <NavLink className={styles.singleLink} to={PATHS.contacts}>
                                {t('CONTACTS')}
                            </NavLink>
                            <img src={arrowFooter} alt="arrowFooter" className={styles.footerIcon} />
                        </div>
                    </div>
                    <div className={styles.footerCopyRight}>© 2023 {t('All rights reserved.')}</div>
                </div>
            )}
            {!mobileWidth && (
                <div className={styles.footerFrame}>
                    <div className={styles.footerLeftPart}>
                        {i18n.language === 'ru' && <img src={Logo} alt={t('Forvard')} width={151} height={81} />}
                        {i18n.language === 'en' && <img src={Logo_eng} alt={t('Forvard')} width={151} height={81} />}
                        <div className={styles.footerPhone}>+7(495)128-06-62</div>
                        <div className={styles.footerMail}>info@forvard.me</div>
                        <div className={styles.footerCopyRight}>© 2023 {t('All rights reserved.')}</div>
                    </div>
                    <div className={styles.footerCenterPart}>
                        <div className={styles.footerCaption}>
                            {t('SERVICES')}
                            <NavLink className={styles.footerLine} to={PATHS.orderCode}>
                                {t('Custom development')}
                            </NavLink>
                            <NavLink className={styles.footerLine} to={PATHS.consulting}>
                                {t('Consulting services')}
                            </NavLink>
                            <NavLink className={styles.footerLine} to={PATHS.techSupport}>
                                {t('Technical support')}
                            </NavLink>
                        </div>
                        <div className={styles.footerCaption}>
                            {t('PRODUCTS')}
                            <NavLink className={styles.footerLine} to={PATHS.fpGate}>
                                {t('FP Gate Payment Gateway')}
                            </NavLink>
                            <NavLink className={styles.footerLine} to={PATHS.topwise}>
                                {t('POS-terminal TOPWISE')}
                            </NavLink>
                            <NavLink className={styles.footerLine} to={PATHS.soyuz}>
                                {t('“Soyuz” mail aggregator')}
                            </NavLink>
                            <NavLink className={styles.footerLine} to={PATHS.acquiring}>
                                {t('Merchant acquiring')}
                            </NavLink>
                            <NavLink className={styles.footerLine} to={PATHS.internetAcquiring}>
                                {t('Internet acquiring')}
                            </NavLink>
                        </div>
                        <div className={styles.footerCaption}>
                            <NavLink className={styles.singleLink} to={PATHS.portfolio}>
                                {t('PORTFOLIO')}
                            </NavLink>
                        </div>
                        <div className={styles.footerCaption}>
                            <div>{t('ABOUT COMPANY')}</div>
                            <NavLink className={styles.footerLine} to={PATHS.contacts} style={{ marginTop: '24px', marginRight: '87px' }}>
                                {t('CONTACTS')}
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
