import React from 'react';
import styles from './internetEquiringContent.less';
import { useTranslation } from 'react-i18next';
import equiringImage from '../.././../assets/images/png/internetEquiring.png';
import nums from '../.././../assets/images/png/nums1to6.png';
import gear from '../../../assets/images/png/gear.png';
import handshake from '../../../assets/images/png/handshake.png';
import tool from '../../../assets/images/png/tool.png';
import bag from '../../../assets/images/png/bag.png';
import bank from '../../../assets/images/png/bank.png';

export const InternetEquiringContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.equiringHead}>
                <div className={styles.textContainer}>
                    <h1 className={styles.heading}>{t('Internet acquiring')} -</h1>
                    <p className={styles.text}>
                        {t('a service provided by a bank for accepting non-cash payments when selling goods and services via the Internet.')}
                    </p>

                    <p className={styles.text}>
                        {t(
                            'The buyer can make payment within a couple of minutes. You can make a payment using plastic and virtual cards, electronic wallets, Internet banking. Client data protection is provided by the acquiring bank. Payment security is guaranteed by PCIDSS security protocols. Banks and payment services strictly monitor the compliance of all transactions with 3D Secure standards. Payment transactions are confirmed by one-time passwords received in the form of SMS messages or push notifications.',
                        )}
                    </p>
                    <h2 className={styles.secondaryHeading}>{t('Why is it beneficial?')}</h2>
                </div>
                <div className={styles.imageContainer}>
                    <img src={equiringImage} alt="equiring" />
                </div>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        <img src={gear} alt="gear" />
                        <p className={styles.listText}>{t('Fully automated remote payment method')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={handshake} alt="handshake" />
                        <p className={styles.listText}>{t('Causes more confidence among buyers than transfers to the card')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={tool} alt="tool" />
                        <p className={styles.listText}>{t('No need to purchase additional equipment')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={bag} alt="bag" />
                        <p className={styles.listText}>{t('Available for all forms of business: self-employed, sole proprietorship, LLC, OJSC')}</p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={bank} alt="bank" />
                        <p className={styles.listText}>{t('Easy to connect through a bank or payment service')}</p>
                    </li>
                </ul>
            </div>
            <div className={styles.howItWorksContainer}>
                <h1 className={styles.howItWorksHeading}>{t('How it works?')}</h1>

                <div className={styles.numsBlock}>
                    <img src={nums} alt="nums" className={styles.nums} />
                    <div className={styles.numsTextWrapper}>
                        <p className={styles.numsText}>
                            {t('The buyer selects a product on the site, places an order and chooses a payment method')}
                        </p>
                        <p className={styles.numsText}>{t('Fills out the payment form and confirms the operation by entering a one-time code')}</p>
                        <p className={styles.numsText}>
                            {t("The provider checks the authenticity of the data and the sufficiency of funds on the buyer's account")}
                        </p>
                        <p className={styles.numsText}>
                            {t('The information is sent to the processing center, which redirects it to the payment system')}
                        </p>
                        <p className={styles.numsText}>
                            {t("After receiving a response from the payment system, money from the buyer's account is transferred to the seller")}
                        </p>
                        <p className={styles.numsText}>{t('Check arrives by email')}</p>
                    </div>
                </div>
            </div>
        </>
    );
};
