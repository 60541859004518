import React from 'react';
import { useTranslation } from 'react-i18next';

export const Services = () => {
    const { t } = useTranslation();
    return (
        <div>
            <section>
                <div>{t('Services')}</div>
            </section>
        </div>
    );
};
