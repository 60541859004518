import tinkoff_black from '../../../assets/images/png/tinkoff_black.png';
import tinkoff_color from '../../../assets/images/png/tinkoff_color.png';
import otkritie_black from '../../../assets/images/png/otkritie_black.png';
import otkritie_color from '../../../assets/images/png/otkritie_color.png';
import rsb_black from '../../../assets/images/png/rsb_black.png';
import rsb_color from '../../../assets/images/png/rsb_color.png';
import psb_black from '../../../assets/images/png/psb_black.png';
import psb_color from '../../../assets/images/png/psb_color.png';
import post_black from '../../../assets/images/png/post_black.png';
import post_color from '../../../assets/images/png/post_color.png';
import qiwi_black from '../../../assets/images/png/qiwi_black.png';
import qiwi_color from '../../../assets/images/png/qiwi_color.png';
import dei_black from '../../../assets/images/png/dei_black.png';
import dei_color from '../../../assets/images/png/dei_color.png';
import contact_black from '../../../assets/images/png/contact_black.png';
import contact_color from '../../../assets/images/png/contact_color.png';
import cloud_black from '../../../assets/images/png/cloud_black.png';
import cloud_color from '../../../assets/images/png/cloud_color.png';
import openway_black from '../../../assets/images/png/openway_black.png';
import openway_color from '../../../assets/images/png/openway_color.png';
import bpc_black from '../../../assets/images/png/bpc_black.png';
import bpc_color from '../../../assets/images/png/bpc_color.png';

export const carouselListData = [
    {
        company: 'Tinkoff',
        blackImg: [tinkoff_black],
        colorImg: [tinkoff_color]
    },
    {
        company: 'Otkritie',
        blackImg: [otkritie_black],
        colorImg: [otkritie_color]
    },
    {
        company: 'RSB',
        blackImg: [rsb_black],
        colorImg: [rsb_color]
    },
    {
        company: 'PSB',
        blackImg: [psb_black],
        colorImg: [psb_color]
    },
    {
        company: 'RussianPost',
        blackImg: [post_black],
        colorImg: [post_color]
    },
    {
        company: 'Qiwi',
        blackImg: [qiwi_black],
        colorImg: [qiwi_color]
    },
    {
        company: 'Deiteriy',
        blackImg: [dei_black],
        colorImg: [dei_color]
    },
    {
        company: 'Contact',
        blackImg: [contact_black],
        colorImg: [contact_color]
    },
    {
        company: 'Cloud4Y',
        blackImg: [cloud_black],
        colorImg: [cloud_color]
    },
    {
        company: 'OpenWay',
        blackImg: [openway_black],
        colorImg: [openway_color]
    },
    {
        company: 'BpcGroup',
        blackImg: [bpc_black],
        colorImg: [bpc_color]
    },
];
