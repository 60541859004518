import React, { useRef } from 'react';
import { MainHeading } from '../../components/mainHeading/mainHeading';
import { MainServices } from '../../components/mainServices/mainServices';
import { MainPortfolio } from '../../components/mainPortfolio/mainPortfolio';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import { Products } from '../../components/products/products';
import { useMobileWidth } from '../../utils/custom-hooks';

export const Main = () => {
    const ref = useRef(null);

    const scrollToForm = () => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const mobileWidth = useMobileWidth();
    return (
        <div>
            <section>
                <MainHeading scrollToForm={scrollToForm} />
                <MainServices />
                <Products />
                {!mobileWidth && <MainPortfolio />}
                <ApplicationForm ref={ref} />
            </section>
        </div>
    );
};
