import React from 'react';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import { ConsultingContent } from '../../components/consultingContent/consultingContent';

export const Consulting = () => {
    return (
        <div>
            <section>
                <ConsultingContent />
                <ApplicationForm variant="consulting" />
            </section>
        </div>
    );
};
