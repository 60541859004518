import Tele2White from '../../../assets/images/png/Tele2White.png';
import otkritieBlue from '../../../assets/images/png/otkritieLogoBlue.png';
import efrRed from '../../../assets/images/png/efrRed.png';
import fourCompanies from '../../../assets/images/png/fourCompaniesColored.png';
import kredoGreen from '../../../assets/images/png/kredoGreen.png';
import amraYellow from '../../../assets/images/png/amraYellow.png';
import companies4Mobile from '../../../assets/images/png/companies4Mobile.png';

export const portfolioCardsData = [
    {
        variant: '',
        title: 'SBP payment for Tele2',
        text: 'As part of the payment gateway, it is possible to credit funds to the accounts of Tele2 subscribers using the SBP.',
        img: Tele2White,
        mobileImg: Tele2White,
        hasDetails: false,
    },
    {
        variant: '',
        title: 'Software development for Android POS-terminal',
        text: 'Development and operation of software for Android Sunme POS-terminals.',
        img: otkritieBlue,
        hasDetails: true,
        mobileImg: otkritieBlue,
        modalText:
            "Development and operation of software for Android POS-terminal. We integrated software with a payment gateway, which made it possible to enter the recipient's card number and amount on the terminal and make payments online.We developed a system for controlling the access of merchant employees to the terminal, a mechanism for signing each payment approved by the Security Council of Otkritie Bank.",
    },
    {
        variant: '',
        title: "Internet acquiring for credit cooperative 'Credo'",
        text: "Development and operation of the payment gateway. We ensured the acceptance of payments for the repayment of loans of the cooperative 'Credo', optimized the cost of the transaction.",
        img: kredoGreen,
        mobileImg: kredoGreen,
        hasDetails: false,
    },
    {
        variant: '',
        title: 'MVP of the financial marketplace',
        text: 'Development of an MVP application for a financial marketplace. Development and implementation of website design.',
        img: efrRed,
        mobileImg: efrRed,
        hasDetails: true,
        modalText:
            'Operational MVP development. In a short time, we developed the MVP of the financial marketplace for presentation to the Central Bank. As part of the MVP, individuals can pick up offers from Banks for a loan, credit debit cards, life insurance, make an appointment with a courier to receive a card, sign and pass identification.',
    },
    {
        variant: 'big',
        title: 'Payment Gateway',
        text: 'Development and operation of the payment gateway. Provided customers with access to several acquiring banks within one integration. Optimized the cost of a transaction online, right at the moment of execution, by choosing the optimal rate in real time, taking into account the type of transaction, amount, card product and other conditions.',
        img: fourCompanies,
        mobileImg: companies4Mobile,
        hasDetails: false,
    },
    {
        variant: '',
        title: 'Payment gateway for AmraBank',
        text: 'Integration with PLUSPAY payment gateway.',
        img: amraYellow,
        mobileImg: amraYellow,
        hasDetails: false,
    },
];
