import React, { useMemo, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import defaultStyles from './navigationStyles.less';
import mobileStyles from './navigationMobileStyles.less';
import { NavPolygon } from './navPolygon';
import { PATHS } from '../../app';
import { useOutsideAlerter } from '../../utils/custom-hooks';
import Burger from '../../../assets/images/png/burger.png';
import { useMobileWidth } from '../../utils/custom-hooks';
import Logo from '../../../assets/images/png/logo_header.png';
import Close from '../../../assets/images/svg/closeMobile.svg';
import ArrowBack from '../../../assets/images/svg/arrowBack.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ignoreMobileLocations } from '../../utils/common-helper';

const SUB_NAMES = {
    products: 'products',
    services: 'services',
    mobile: 'mobile',
};

export const Navigation = () => {
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    const serviceMenuRef = useRef();
    const [subOpenName, setSubOpenName] = useState(null);
    const toggleSubMenu = (name) => {
        const openSubMenuName = name === subOpenName ? null : name;
        setSubOpenName(openSubMenuName);
    };

    const ignoreMobile = ignoreMobileLocations.includes(location.pathname);
    const mobileWidth = useMobileWidth() && !ignoreMobile;

    const styles = useMemo(() => {
        return ignoreMobile ? defaultStyles : mobileStyles;
    }, [location]);

    const closeMenu = (e) => {
        setSubOpenName(null);
    };

    useOutsideAlerter(serviceMenuRef, closeMenu);

    return (
        <nav className={styles.menu}>
            {mobileWidth && (
                <>
                    <img
                        src={Burger}
                        alt="Burger"
                        className={styles.burger}
                        onClick={() => {
                            toggleSubMenu(SUB_NAMES.mobile);
                        }}
                    />
                    {subOpenName === SUB_NAMES.mobile && (
                        <>
                            <div className={styles.fader} onClick={closeMenu}></div>
                            <div className={styles.mobileMenu}>
                                <div className={styles.mobileMenuHead}>
                                    <img src={Logo} alt="Logo" width={'54px'} height={'30px'} />
                                    <img src={Close} alt="Close" width={'15px'} height={'15px'} onClick={closeMenu} />
                                </div>
                                <div className={classNames(styles.mobileMenuItem, styles.arrow)}>
                                    <NavLink
                                        className={styles.mobileMenuItemLink}
                                        onClick={() => {
                                            toggleSubMenu(SUB_NAMES.services);
                                        }}
                                    >
                                        {t('SERVICES')}
                                    </NavLink>
                                </div>
                                <div className={classNames(styles.mobileMenuItem, styles.arrow)}>
                                    <NavLink
                                        className={styles.mobileMenuItemLink}
                                        onClick={() => {
                                            toggleSubMenu(SUB_NAMES.products);
                                        }}
                                    >
                                        {t('PRODUCTS')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLink} to={PATHS.portfolio} onClick={closeMenu}>
                                        {t('PORTFOLIO')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLink} to={PATHS.about} onClick={closeMenu}>
                                        {t('ABOUT COMPANY')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLink} to={PATHS.contacts} onClick={closeMenu}>
                                        {t('CONTACTS')}
                                    </NavLink>
                                </div>
                                <div className={classNames(styles.mobileMenuItem, styles.flex)}>
                                    <p
                                        className={classNames(styles.languageSwitcher, i18n.language === 'ru' && styles.active)}
                                        onClick={() => changeLanguage('ru')}
                                    >
                                        RU
                                    </p>
                                    <p
                                        className={classNames(styles.languageSwitcher, i18n.language === 'en' && styles.active)}
                                        onClick={() => changeLanguage('en')}
                                    >
                                        EN
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {subOpenName === SUB_NAMES.services && (
                        <>
                            <div className={styles.fader} onClick={closeMenu}></div>
                            <div className={styles.mobileMenu}>
                                <div className={classNames(styles.mobileMenuHead, styles.opened)}>
                                    <img
                                        src={ArrowBack}
                                        alt="arrowBack"
                                        width={'11px'}
                                        height={'19px'}
                                        onClick={() => {
                                            toggleSubMenu(SUB_NAMES.mobile);
                                        }}
                                    />
                                    <img src={Close} alt="Close" width={'15px'} height={'15px'} onClick={closeMenu} />
                                </div>
                                <h1 className={styles.mobileMenuHeading}> {t('SERVICES')}</h1>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.orderCode} onClick={closeMenu}>
                                        {t('Custom development')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.consulting} onClick={closeMenu}>
                                        {t('Consulting services')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.techSupport} onClick={closeMenu}>
                                        {t('Technical support')}
                                    </NavLink>
                                </div>
                                <div className={classNames(styles.mobileMenuItem, styles.flex)}>
                                    <p
                                        className={classNames(styles.languageSwitcher, i18n.language === 'ru' && styles.active)}
                                        onClick={() => changeLanguage('ru')}
                                    >
                                        RU
                                    </p>
                                    <p
                                        className={classNames(styles.languageSwitcher, i18n.language === 'en' && styles.active)}
                                        onClick={() => changeLanguage('en')}
                                    >
                                        EN
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {subOpenName === SUB_NAMES.products && (
                        <>
                            <div className={styles.fader} onClick={closeMenu}></div>
                            <div className={styles.mobileMenu}>
                                <div className={classNames(styles.mobileMenuHead, styles.opened)}>
                                    <img
                                        src={ArrowBack}
                                        alt="arrowBack"
                                        width={'11px'}
                                        height={'19px'}
                                        onClick={() => {
                                            toggleSubMenu(SUB_NAMES.mobile);
                                        }}
                                    />
                                    <img src={Close} alt="Close" width={'15px'} height={'15px'} onClick={closeMenu} />
                                </div>
                                <h1 className={styles.mobileMenuHeading}>{t('PRODUCTS')}</h1>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.fpGate} onClick={closeMenu}>
                                        {t('FP Gate Payment Gateway')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.topwise} onClick={closeMenu}>
                                        {t('POS-terminal TOPWISE')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.acquiring} onClick={closeMenu}>
                                        {t('Merchant acquiring')}
                                    </NavLink>
                                </div>
                                <div className={styles.mobileMenuItem}>
                                    <NavLink className={styles.mobileMenuItemLinkRoboto} to={PATHS.internetAcquiring} onClick={closeMenu}>
                                        {t('Internet acquiring')}
                                    </NavLink>
                                </div>
                                <div className={classNames(styles.mobileMenuItem, styles.flex)}>
                                    <p
                                        className={classNames(styles.languageSwitcher, i18n.language === 'ru' && styles.active)}
                                        onClick={() => changeLanguage('ru')}
                                    >
                                        RU
                                    </p>
                                    <p
                                        className={classNames(styles.languageSwitcher, i18n.language === 'en' && styles.active)}
                                        onClick={() => changeLanguage('en')}
                                    >
                                        EN
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

            {!mobileWidth && (
                <>
                    <div className={styles.menuItem}>
                        <div
                            className={styles.menuItemLink}
                            onClick={() => {
                                toggleSubMenu(SUB_NAMES.services);
                            }}
                        >
                            {t('SERVICES')}
                        </div>
                        {subOpenName === SUB_NAMES.services && (
                            <div className={styles.subMenu} ref={serviceMenuRef} onClick={closeMenu}>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.orderCode}>
                                    {t('Custom development')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.consulting}>
                                    {t('Consulting services')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.techSupport}>
                                    {t('Technical support')}
                                </NavLink>
                            </div>
                        )}
                        <NavPolygon open={subOpenName === SUB_NAMES.services} />
                    </div>

                    <div className={styles.menuItem}>
                        <div
                            className={styles.menuItemLink}
                            onClick={() => {
                                toggleSubMenu(SUB_NAMES.products);
                            }}
                        >
                            {t('PRODUCTS')}
                        </div>

                        {subOpenName === SUB_NAMES.products && (
                            <div className={styles.subMenu} ref={serviceMenuRef} onClick={closeMenu}>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.fpGate}>
                                    {t('FP Gate Payment Gateway')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.topwise}>
                                    {t('POS-terminal TOPWISE')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.acquiring}>
                                    {t('Merchant acquiring')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.internetAcquiring}>
                                    {t('Internet acquiring')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.soyuz}>
                                    {t('“Soyuz” mail aggregator')}
                                </NavLink>
                                <NavLink className={styles.menuSubItemLink} to={PATHS.selfEmployedPlatform}>
                                    {t('Self employed platform')}
                                </NavLink>
                            </div>
                        )}

                        <NavPolygon open={subOpenName === SUB_NAMES.products} />
                    </div>

                    <div className={styles.menuItem}>
                        <NavLink className={styles.menuItemLink} to={PATHS.portfolio}>
                            {t('PORTFOLIO')}
                        </NavLink>
                    </div>
                    <div className={styles.menuItem}>
                        <NavLink className={styles.menuItemLink} to={PATHS.about}>
                            {t('ABOUT COMPANY')}
                        </NavLink>
                    </div>
                    <div className={styles.menuItem}>
                        <NavLink className={styles.menuItemLink} to={PATHS.contacts}>
                            {t('CONTACTS')}
                        </NavLink>
                    </div>
                </>
            )}
        </nav>
    );
};
