import { PortfolioList } from '../../components/portfolioList/portfolioList';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import styles from './portfolioStyles.less';

export const Portfolio = () => {
    return (
        <div>
            <section className={styles.portfolio}>
                <PortfolioList />
                <ApplicationForm />
            </section>
        </div>
    );
};
