import React from 'react';
import styles from './equiringContent.less';
import { useTranslation } from 'react-i18next';
import equiringImage from '../.././../assets/images/png/equiringContent.png';
import nums from '../.././../assets/images/png/nums1to4.png';

export const AcquiringContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.equiringHead}>
                <div className={styles.textContainer}>
                    <h1 className={styles.heading}>{t('Merchant acquiring')} -</h1>
                    <p className={styles.text}>
                        {t(
                            "is a service provided by a bank for accepting non-cash payments at a point of sale. Payment is made from the client's bank card through specialized equipment - POS-terminal.",
                        )}
                    </p>
                    <h2 className={styles.secondaryHeading}>{t('For what?')}</h2>
                    <p className={styles.text}>
                        {t(
                            'Acceptance of payments by bank cards optimizes the work of the store, eliminates the need to work with cash and constantly monitor the receipt and availability of the amount necessary for settlements. Merchant acquiring is an example of how the introduction of innovative technologies helps businesses.',
                        )}
                    </p>
                    <h2 className={styles.secondaryHeading}>{t('Why is it beneficial?')}</h2>
                    <p className={styles.text}>
                        {t(
                            "Shoppers in today's world are increasingly weaned from the use of cash. And the organization of acceptance of non-cash payments is already a necessity.",
                        )}
                    </p>
                    <ul className={styles.list}>
                        <li className={styles.listItem}>
                            {t('There is no risk of receiving counterfeit money. Working with cash is fraught with such problems.')}
                        </li>
                        <li className={styles.listItem}>
                            {t(
                                'Cashiers work easier and faster. No need to count banknotes, give change, there is no fear of counting incorrectly. Servicing one client takes less time.',
                            )}
                        </li>
                        <li className={styles.listItem}>{t('Collection is not needed - the money will immediately go to the current account.')}</li>
                        <li className={styles.listItem}>
                            {t('Revenue increases. If you can pay with a card, people often tend to spend 10-15% more than usual.')}
                        </li>
                        <li className={styles.listItem}>
                            {t(
                                'The number of buyers who prefer cashless payments is growing. If the store only accepts cash, the chances are high that the customer will go to a more modern competitor.',
                            )}
                        </li>
                    </ul>
                </div>
                <div className={styles.imageContainer}>
                    <img src={equiringImage} alt="equiring" />
                </div>
            </div>
            <div className={styles.howItWorksContainer}>
                <h1 className={styles.howItWorksHeading}>{t('How it works?')}</h1>
                <p className={styles.howItWorksText}>
                    {t(
                        'The buyer’s bank card is involved in cashless payments - these can be debit or credit cards of various payment systems: Mir, Visa, Mastercard, etc. The purchase amount is debited from the balance of the buyer’s card and transferred to the seller’s account. Payment goes like this:',
                    )}
                </p>
                <div className={styles.numsBlock}>
                    <img src={nums} alt="nums" className={styles.nums} />
                    <div className={styles.numsTextWrapper}>
                        <p className={styles.numsText}>
                            {t(
                                'The processing center of the acquiring bank receives an authorization request for the transaction. The card passes checks on the side of the acquirer and, if it is active and not in the blacklists, the authorization request is sent to the issuer (the bank that issued the card)',
                            )}
                        </p>
                        <p className={styles.numsText}>
                            {t(
                                'The issuing bank also checks the status of the card, checks the balance. If there is enough money, the required amount is blocked on the account',
                            )}
                        </p>
                        <p className={styles.numsText}>
                            {t(
                                'The POS-terminal issues a message about the successful completion of the transaction. The buyer receives a confirmation receipt',
                            )}
                        </p>
                        <p className={styles.numsText}>{t('The whole process takes a few seconds')}</p>
                    </div>
                </div>
            </div>
        </>
    );
};
