import styles from './concept.less';
import { useTranslation } from 'react-i18next';
import Cash from '../../../assets/images/png/Cash.png';
import Check from '../../../assets/images/png/Check.png';
import Document from '../../../assets/images/png/Document.png';

export const Concept = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1 className={styles.conceptHeading}>{t('Concept')}</h1>
            <ul className={styles.conceptList}>
                <li className={styles.conceptListItem}>
                    <img src={Document} alt="doc" className={styles.conceptImg} width={134} height={150} />
                    <h2 className={styles.mainText}>{t('Electronic document management')}</h2>
                    <p className={styles.secondaryText}>{t('based on the internal Certification Authority')}</p>
                </li>
                <li className={styles.conceptListItem}>
                    <img src={Check} alt="check" className={styles.conceptImg} width={150} height={150} />
                    <h2 className={styles.mainText}>{t('Automated verification')}</h2>
                    <p className={styles.secondaryText}>{t('of self-employed status and generation of checks at the time of payments')}</p>
                </li>
                <li className={styles.conceptListItem}>
                    <img src={Cash} alt="cash" className={styles.conceptImg} width={170} height={150} />
                    <h2 className={styles.mainText}>{t('Fintech solutions')}</h2>
                    <p className={styles.secondaryText}>
                        {t('for automating mass payments Self-employed with a minimum bank commission for individuals')}
                    </p>
                </li>
            </ul>
        </div>
    );
};
