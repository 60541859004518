import { Link } from 'react-router-dom';
import { useMobileWidth } from '../../utils/custom-hooks';
import { BlurryCard } from '../blurryCard/blurryCard';
import { productCardsData } from './products.helper';
import styles from './products.less';
import { useTranslation } from 'react-i18next';
import LongArrow from '../../../assets/images/svg/longArrow.svg';
import { Button } from '../button/button';
import { PATHS } from '../../app';

export const Products = () => {
    const { t } = useTranslation();
    const mobileWidth = useMobileWidth();
    return (
        <div className={styles.container}>
            <h1 className={styles.productsHeading}>{t('Products')}</h1>
            {!mobileWidth && (
                <ul className={styles.productCards}>
                    {productCardsData.map((el, idx) => {
                        return <BlurryCard {...el} key={idx} />;
                    })}
                </ul>
            )}
            {mobileWidth && (
                <>
                    <ul className={styles.mobileProducts}>
                        {productCardsData.map((el, idx) => {
                            return (
                                <li className={styles.mobileProductsItem} key={idx}>
                                    <Link to={el.to} className={styles.link}>
                                        <p>{t(el.title)}</p>
                                        <img src={LongArrow} alt="LongArrow" />
                                    </Link>
                                </li>
                            );
                        })}
                        <Link to={PATHS.portfolio} className={styles.linkBtn}>
                            <Button variant="main">{t('To portfolio')}</Button>
                        </Link>
                    </ul>
                </>
            )}
        </div>
    );
};
