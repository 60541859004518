import { useState } from 'react';
import { PortfolioCard } from '../portfolioCard/portfolioCard';
import { portfolioCardsData } from './portfolioList.helper';
import styles from './portfolioList.less';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal/Modal';

export const PortfolioList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setIsOpen(true);
        setModalContent(content);
    };

    const closeModal = () => {
        setIsOpen(false);
        setModalContent(null);
    };

    const modalProps = { ...modalContent, closeModal };

    const { t } = useTranslation();
    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.portfolioHeading}>{t('PORTFOLIO')}</h1>
                <ul className={styles.portfolioList}>
                    {portfolioCardsData.map((el, idx) => {
                        return (
                            <li key={idx}>
                                <PortfolioCard {...el} openModal={openModal} />
                            </li>
                        );
                    })}
                </ul>
            </div>
            {isOpen && <Modal {...modalProps} />}
        </>
    );
};
