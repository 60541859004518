import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/app.jsx';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { ScrollToTop } from './app/components/scrollTop/scrollTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ScrollToTop/>
        <App />
    </BrowserRouter>,
);
