import React from 'react';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import { ContactsMap } from '../../components/contactsMap/contactsMap';

export const Contacts = () => {
    return (
        <div>
            <section>
                <ContactsMap />
                <ApplicationForm />
            </section>
        </div>
    );
};
