import React from 'react';
import styles from './techSupportContent.less';
import { useTranslation } from 'react-i18next';
import gearFrame from '../.././../assets/images/png/gearFrame.png';
import check2 from '../../../assets/images/png/check2.png';
import toolGear from '../../../assets/images/png/toolGear.png';
import hat from '../../../assets/images/png/hat.png';
import reload from '../../../assets/images/png/reload.png';

export const TechSupportContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.techSupportHead}>
                <div className={styles.textContainer}>
                    <h1 className={styles.heading}>{t('Technical support')}</h1>
                    <p className={styles.text}>
                        {t(
                            'We provide your business not only with a high-quality solution, but also with full technical support. This means that you can count on reliable and fast assistance should problems or questions arise.',
                        )}
                    </p>

                    <p className={styles.text}>
                        {t(
                            "An idle Internet resource can result in serious losses. That's why it's so important to set up your own technical support system or contact \nprofessionals.",
                        )}
                    </p>
                    <h2 className={styles.secondaryHeading}>{t('technical support from “forvard”')}</h2>
                    <ul className={styles.techSupportList}>
                        <li className={styles.techSupportItem}>
                            {t('Guaranteed high performance, speed and availability of even large Internet projects')}
                        </li>
                        <li className={styles.techSupportItem}>{t('Quick troubleshooting of any complexity')}</li>
                        <li className={styles.techSupportItem}>{t('Qualified team for the development of your project')}</li>
                    </ul>
                </div>
                <div className={styles.imageContainer}>
                    <img src={gearFrame} alt="gearFrame" />
                </div>
            </div>
            <div className={styles.techSupportVariants}>
                <h2 className={styles.techSupportVariantsHeading}>{t('Types of technical support from “forvard”')}</h2>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        <img src={check2} alt="check2" />
                        <p className={styles.listTextUp}>{t('Warranty Support')}</p>
                        <p className={styles.listText}>
                            {t(
                                'We provide a guarantee\nfor the work performed - within a certain time after the completion of the project, we will correct any errors free of charge.',
                            )}
                        </p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={toolGear} alt="gear" />
                        <p className={styles.listTextUp}>{t('Technical support on an ongoing basis')}</p>
                        <p className={styles.listText}>
                            {t('Always ready to help\nwith any problems\nthat may arise during the use of the software')}
                        </p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={hat} alt="hat" />
                        <p className={styles.listTextUp}>{t('Training and Documentation')}</p>
                        <p className={styles.listText}>
                            {t(
                                'We pay special attention to ensuring that you feel comfortable using our product and know how to use\n its capabilities as efficiently as possible.',
                            )}
                        </p>
                    </li>
                    <li className={styles.listItem}>
                        <img src={reload} alt="reload" />
                        <p className={styles.listTextUp}>{t('Updates and improvements')}</p>
                        <p className={styles.listText}>
                            {t(
                                'We regularly release updates and improve our software to ensure its safety and performance. We also provide the ability to integrate new features and improvements into your software.',
                            )}
                        </p>
                    </li>
                </ul>
            </div>
        </>
    );
};
