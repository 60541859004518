import Tele2 from '../../../assets/images/png/Tele2.png';
import otkritieLogo from '../../../assets/images/png/otkritie.png';
import efr from '../../../assets/images/png/efr.png';
import kredo from '../../../assets/images/png/kredo.png';
import amra from '../../../assets/images/png/amra.png';
import fourCompanies from '../../../assets/images/png/fourCompaniesGrey.png';

export const companiesListData = [
    {
        company: 'Tele2',
        companyImg: [Tele2],
        hoverText: 'As part of the payment gateway, it is possible to credit funds to the accounts of Tele2 subscribers using the SBP',
        variant: 'small',
    },
    {
        company: 'OtkritiePSBRusStandartQiwi',
        companyImg: [fourCompanies],
        hoverText:
            'Development and operation of a payment gateway that provided access to several acquiring banks within one integration + optimization of the cost of a transaction online immediately at the time of its execution by selecting the optimal rate in real time, taking into account the type of transaction, amount, card product and other conditions',
        variant: 'big',
    },
    {
        company: 'EFR',
        companyImg: [efr],
        hoverText: 'Development of an MVP application for a financial marketplace',
        variant: 'small',
    },
    {
        company: 'Otkritie',
        companyImg: [otkritieLogo],
        hoverText: 'Development and operation of software for Android POS-terminals Sunme',
        variant: 'big',
    },
    {
        company: 'Kredo',
        companyImg: [kredo],
        hoverText:
            "Within the framework of the payment gateway, we ensured the acceptance of payments for the repayment of loans to CPC 'Credo Plus' with the optimization of the cost of the transaction",
        variant: 'small',
    },
    {
        company: 'Amrabank',
        companyImg: [amra],
        hoverText: 'Integration with PLUSPAY payment gateway',
        variant: 'small',
    },
];
