import { Button } from '../button/button';
import styles from './blurryCard.less';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const BlurryCard = ({ img, title, onClick, to }) => {
    const { t } = useTranslation();

    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <div className={styles.container} onClick={onClick}>
                <div className={styles.imgWrapper}>
                    <img className={styles.blurryCardImg} src={img} alt={t(title)} />
                </div>
                <h2 className={styles.blurryCardTitle}>{t(title)}</h2>
                <div className={styles.btnWrapper}>
                    <Button variant="secondary">{t('More')}</Button>
                </div>
            </div>
        </Link>
    );
};
