import { PortfolioCard } from '../portfolioCard/portfolioCard';
import styles from './cases.less';
import { useTranslation } from 'react-i18next';
import { casesCardsData } from './cases.helper';

export const Cases = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1 className={styles.portfolioHeading}>{t('Implemented cases')}</h1>
            <ul className={styles.portfolioList}>
                {casesCardsData.map((el, idx) => {
                    return (
                        <li key={idx}>
                            <PortfolioCard {...el} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
