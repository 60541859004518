import styles from './aboutHeading.less';
import { useTranslation } from 'react-i18next';

export const AboutHeading = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.blurredFrame}>
                <h1 className={styles.mainText}>{t('ABOUT COMPANY')}</h1>
                <p className={styles.secondaryText}>
                    {t(
                        `We offer a solution that exactly meets the needs of each client, helping large businesses to strengthen their positions, and young startups to conquer the market through the introduction of unique software and services.`,
                    )}
                </p>
            </div>
            <div className={styles.rightFrame}>
                <p className={styles.greenText}>{t('IT-PARTNER FOR SUCCESSFUL BUSINESS')}</p>
            </div>
        </div>
    );
};
