import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import defaultStyles from './layout.less';
import mobileStyles from './layoutMobileStyles.less';
import { Header } from '../header/header';
import { Footer } from '../../entities/footer/footer';
import { useLocation } from 'react-router-dom';
import { ignoreMobileLocations } from '../../utils/common-helper';

export const Layout = () => {
    const location = useLocation();

    const styles = useMemo(() => {
        return ignoreMobileLocations.includes(location.pathname) ? defaultStyles : mobileStyles;
    }, [location]);
    return (
        <div className={styles.layout}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};
