export const cardsData = [
    {
        feature: 'Custom development',
        featuresList: [
            'Corporate and web portals',
            'Personal accounts',
            'Mobile applications',
            'Integration of high-load services for the financial sector, insurance, technology startups',
            'Business Process Optimization Software',
            'Software reengineering',
            'UI/UX development',
        ],
        to: '/order-code-development',
    },
    {
        feature: 'Consulting services',
        featuresList: [
            'Audit of current software, website or mobile application', 'Analytics',
            'UX research, CJM, etc.'],
        to: '/consulting',
    },
    {
        feature: 'Technical support',
        featuresList: [
            'Regular updates and maintenance',
            'Testing and Debugging',
            'Security and data protection',
            'Requirements analysis and refinement',
        ],
        to: '/tech-support',
    },
];
