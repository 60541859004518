import React, { useEffect, useMemo, useState } from 'react';
import defaultStyles from './applicationForm.less';
import mobileStyles from './applicationFormMobile.less';
import { useTranslation } from 'react-i18next';
import { FormFields } from '../../components/form/formFields';
import cn from 'classnames';
import { sendForm } from '../../services/formServices';
// import { Spinner } from '../../components/spinner/spinner';
import { ignoreMobileLocations } from '../../utils/common-helper';
import { useLocation } from 'react-router-dom';

export const ApplicationForm = React.forwardRef((props, ref) => {
    const location = useLocation;
    const { t } = useTranslation();
    const [requestData, setRequestData] = useState(null);
    // const [loading, setLoading] = useState(false);

    const submitForm = (e) => {
        const form = e.target;
        const name = form.name.value;
        const phone = form.phone.value;
        const description = form.description.value;
        try {
            // _reCaptchaRef.current.reset();
            const request = { name, phone, description };
            // setLoading(true);
            setRequestData(request);
        } catch (e) {
            console.error('error', e);
        }
    };

    useEffect(() => {
        if (requestData) {
            (async () => {
                try {
                    const resp = await sendForm(requestData);
                    if (resp.status === 'Ok') {
                        // alert('Ваш запрос отправлен');
                        setRequestData(null);
                    } else {
                        console.log('Сообщение в процессе отправки');
                    }
                } catch (err) {
                    console.log('Сообщение в процессе отправки', err);
                }
                // setLoading(false);
            })();
        }
    }, [requestData]);

    const ignoreMobile = ignoreMobileLocations.includes(location.pathname);

    const styles = useMemo(() => {
        return ignoreMobile ? defaultStyles : mobileStyles;
    }, [location, ignoreMobile]);

    return (
        <div className={cn(styles.container, styles[props.variant])} ref={ref}>
            <h1 className={styles.formHeading}>{t('Leave a request')}</h1>
            <div className={styles.formContainer}>
                {/*{loading && <Spinner />}*/}
                {/*{!loading && <FormFields onSubmit={submitForm} />}*/}
                <FormFields onSubmit={submitForm} />
            </div>
        </div>
    );
});
