import { API_ENDPOINT, PUT_FIELDS, REQUEST_FIELDS, URL_ORDER, URL_SEND_FORM } from '../config/consts';
import { genericErrorMessage } from '../utils/common-helper';

export const postParams = (data, token, method) => {
    const params = 'put' === method ? { ...PUT_FIELDS } : { ...REQUEST_FIELDS };

    if (data) {
        params.body = JSON.stringify(data);
    }

    if (token) {
        params.headers = {
            ...params.headers,
            Authorization: `Bearer ${token}`,
        };
    }

    return params;
};

export const errorHandler = (response) => {
    if (response.status === 400) {
        console.log('Please fill all the fields correctly!');
    } else if (response.status === 401) {
        console.log('Invalid email and password combination.');
    } else if (response.status === 500) {
        const data = response;
        if (data.message) console.log(data.message || genericErrorMessage);
    } else {            // 200, but status not ok (business error)
        return response;
    }
};


export async function handleResponse(response) {
    let resp = await response.json();
    if (resp.status !== 'Ok') {
        return errorHandler(resp);
    } else {
        return resp;
    }
}


export async function sendForm(fields) {
    let response = await fetch(API_ENDPOINT + URL_SEND_FORM, postParams({ ...fields }));
    return handleResponse(response);
}

export async function sendOrder(fields) {
    let response = await fetch(API_ENDPOINT + URL_ORDER, postParams({ ...fields }));
    return handleResponse(response);
}
