export const API_ENDPOINT = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEVELOP : process.env.REACT_APP_API_ENDPOINT;

export const SMALL_SCREEN = window.innerWidth <= 850;

export const URL_SEND_FORM = '/api/send_request';
export const URL_ORDER = '/api/order';

export const reCaptchaProps = {
    language: 'ru',
    useRecaptchaNet: false,
    reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    scriptProps: { async: true, defer: true, appendTo: 'body' },
};

export const REQUEST_FIELDS = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
};
export const PUT_FIELDS = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
};

export const cookieMessage = {
    title: 'Файлы cookie',
    text: ' ',
    type: 'info',
    options: 'cookie',
};


export const languages = [
    { id: 'ru', name: 'Russian' },
    { id: 'en', name: 'English' },
];
