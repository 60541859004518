import React from 'react';
import { AboutHeading } from '../../components/aboutHeading/aboutHeading';
import { AboutForvard } from '../../components/aboutForvard/aboutForvard';
import { AboutWhy } from '../../components/aboutWhy/aboutWhy';
import { Carousel } from '../../components/carousel/carousel';

export const About = () => {
    return (
        <div>
            <section>
                <AboutHeading />
                <AboutForvard />
                <Carousel />
                <AboutWhy />
            </section>
        </div>
    );
};
