import React from 'react';
import styles from './productsPage.less';
import hand1 from '../../../assets/images/png/hand1.png';
import hand2 from '../../../assets/images/png/hand2.png';
import freeUmind from '../../../assets/images/svg/freeUmind.svg';
import businessStyle from '../../../assets/images/svg/businessStyle.svg';
import certBackground from '../../../assets/images/png/certBackground.png';
import cert from '../../../assets/images/png/cert.png';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

//{t("")}
export const ProductsPage = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.productPlace}>
            <div className={styles.block1}>
                <div className={styles.posPicWrapper}>
                    <h2>{t('POS-terminal Topwise')}</h2>
                    <p className={styles.shideText}>{t('Choose the best...')}</p>

                    <div className={styles.flexText}>
                        <div className={cn(styles.sideBlock, styles.side1)}>
                            <h3>
                                TOPWISE <span>T3</span> Smart POS
                            </h3>
                            <div className={styles.listItem}>{t('Latest Version')}</div>
                            <div className={styles.listItem}>{t('Latest design and technology')}</div>
                            <div className={styles.listItem}>{t('Latest EMV and PCI certification')}</div>
                        </div>
                        <div className={cn(styles.sideBlock, styles.side2)}>
                            <h3>
                                TOPWISE <span>T1</span> Smart POS
                            </h3>
                            <div className={styles.listItem}>{t('Latest standard and compatibility')}</div>
                            <div className={styles.listItem}>{t('Supports multi-channel payments')}</div>
                            <div className={styles.listItem}>{t("Large battery, 5.0' HD full screen")}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.block2}>
                <div className={styles.sideTextPlace}>
                    <div className={styles.dots}>
                        <div className={styles.listItem}>{t('Traditional USB Charging Dock')}</div>
                        <div className={styles.listItem}>{t('Charging dock with BT connection')}</div>
                        <div className={styles.listItem}>
                            {t('Support data connection,')}
                            <br />
                            {t('when T3 is disconnected from the docking station')}
                        </div>
                    </div>
                    <div className={styles.buttonsBlock}>
                        <div className={styles.aside}>
                            <div className={styles.greyBlock}>
                                <div className={styles.greenText}>1,5{t('GHz')}</div>
                                <div className={styles.whiteDescr}>{t('Processor faster')}</div>
                            </div>
                            <div className={styles.greyBlock}>
                                <div className={styles.greenText}>Cortex-A53</div>
                                <div className={styles.whiteDescr}>{t('Processor')} Quad Core</div>
                            </div>
                            <div className={styles.greyBlock}>
                                <div className={styles.greenText}>3 GB</div>
                                <div className={styles.whiteDescr}>{t('Maximum')} RAM</div>
                            </div>
                            <div className={styles.greyBlock}>
                                <div className={styles.greenText}>64 GB</div>
                                <div className={styles.whiteDescr}>{t('Maximum flash')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.block3}>
                <div className={styles.girlBg}>
                    <h4>{t('The fastest printer on the market')}</h4>

                    <div className={styles.subtexts}>
                        <div className={styles.text1}>
                            {t('Double battery')} <br />
                            {t('for printer support')}
                        </div>
                        <div className={styles.text1}>{t('High voltage printer')}</div>
                        <div className={styles.text1}>{t('Print software optimization')}</div>
                    </div>
                </div>
            </div>

            <div className={styles.block4}>
                <div className={styles.greyHeadText}>
                    {t('Transactions')} <br />
                    {t('with a large volume')}
                </div>
                <div className={styles.str1}>
                    {t('Print to')} <span>1500</span> {t('receipts')}
                </div>
                <div className={styles.str2}>
                    {t('Up to')} <span>18</span> {t('days in standby mode with a SIM card')}
                </div>
                <div className={styles.str3}>{t('Energy consumption is fully optimized')}</div>
                <img className={styles.freeMindImg} src={freeUmind} alt="Освободите разум для широкого использования" width={840} height={32} />
            </div>

            <div className={styles.block5}>
                <div className={styles.bgWrapper}>
                    <h3 className={styles.topWhiteBlockText}>
                        {t('Innovative design of the')} <br />
                        {t('TOPWISE T3 Smart POS front camera')}
                    </h3>
                    <p>
                        {t('Easy and fast reading')} <br />
                        {t('1D and 2D barcode')}
                    </p>
                    <h3 className={styles.centerText}>{t('Accept all types of payments')}</h3>
                    <div className={styles.descriptionLine}>
                        <div>{t('Magnetic stripe card')}</div>
                        <div>{t('EMV card')}</div>
                        <div>{t('NFC contactless')}</div>
                        <div>{t('MIR and Apple Pay')}</div>
                    </div>

                    <img src={hand1} className={styles.hand1} alt="pay by nfc" />
                    <img src={hand2} className={styles.hand2} alt="pay by beer" />
                </div>
            </div>

            <div className={styles.block6}>
                <img src={businessStyle} alt="Задайте стиль своему бизнесу" width={535} height={32} className={styles.businessImg} />
                <p className={styles.greyText}>{t('Individual color solutions')}</p>
            </div>

            <div className={styles.block7}>
                <img src={cert} width={349} height={496} className={styles.cert} />
                <img src={certBackground} width={1045} height={481} className={styles.certBackground} />
                <p className={styles.greenTextCert}>{t('Only certified products')}</p>
                <p className={styles.greyText}>{t('Foundation of Trust')}</p>
            </div>

            <div className={styles.block8}>
                <ApplicationForm />
            </div>
        </section>
    );
};
