import { Link } from 'react-router-dom';
import styles from './simpleBlackCard.less';
import { useTranslation } from 'react-i18next';

export const SimpleBlackCard = ({ feature = 'feature', featuresList = [], onClick, to }) => {
    const { t } = useTranslation();
    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <div className={styles.simpleBlackCard} onClick={onClick}>
                <h1 className={styles.feature}>{t(feature)}</h1>
                <ul className={styles.list}>
                    {featuresList.map((el, idx) => {
                        return (
                            <li key={idx} className={styles.featuresListItem}>
                                {t(el)}
                            </li>
                        );
                    })}
                </ul>
                <button className={styles.detailsBtn}>{t('More')}</button>
            </div>
        </Link>
    );
};
