import { useState } from 'react';
import styles from './ddBlock.less';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import chevron from '../../../assets/images/png/Chevron.png';

const defaultState = {
    dd1Open: false,
    dd2Open: false,
};

export const DdBlock = () => {
    const [isOpen, setIsOpen] = useState(defaultState);

    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{t('How it works?')}</h1>
            <div className={styles.ddBlockWrapper}>
                <div className={cn(styles.dd, isOpen.dd1Open && styles.ddOpen)}>
                    <div className={styles.wrapper}>
                        <h1 className={styles.ddHead}>{t('Online platform for placing applications')}</h1>
                        <img
                            src={chevron}
                            alt="chevron"
                            className={cn(styles.chevron, isOpen.dd1Open && styles.active)}
                            onClick={() => setIsOpen((prev) => ({ ...prev, dd1Open: !prev.dd1Open }))}
                        />
                    </div>
                    <ul className={styles.list}>
                        <li className={styles.listItem}>{t('Registration of the customer on the online platform')}</li>
                        <li className={styles.listItem}>{t('Submission of an application for the provision of services/performance of works')}</li>
                        <li className={styles.listItem}>{t('Automated selection of the Contractor from the current database')}</li>
                        <li className={styles.listItem}>
                            {t(
                                'Implementation of automated mailing of proposals for the provision of services/performance of work by a potential contractor',
                            )}
                        </li>
                        <li className={styles.listItem}>
                            {t(
                                'Automated distribution of proposals for the provision of services/performance of work to potential contractors from a list selected according to the requirements of the Customer',
                            )}
                        </li>
                        <li className={styles.listItem}>{t('Receiving feedback on the readiness of execution from a potential Contractor')}</li>
                        <li className={styles.listItem}>
                            {t('Formation of a contract for the provision of services/performance of work between the Customer and the Operator')}
                        </li>
                        <li className={styles.listItem}>{t('Transferring a deposit payment to the Operator of the online platform')}</li>
                        <li className={styles.listItem}>
                            {t('Provision by the Contractor of services/performance of work in accordance with the application of the Customer')}
                        </li>
                        <li className={styles.listItem}>
                            {t(
                                'Electronic report by the Customer of the actual results of work through a mobile application and the formation of intermediate electronic acts',
                            )}
                        </li>
                        <li className={styles.listItem}>
                            {t('Formation of the final acts of the work performed in accordance with the application of the Customer')}
                        </li>
                    </ul>
                </div>
                <div className={cn(styles.dd, styles.right, isOpen.dd2Open && styles.ddOpen)}>
                    <div className={styles.wrapper}>
                        <h1 className={styles.ddHead}>{t('Secure payment service  for self-employed')}</h1>
                        <img
                            src={chevron}
                            alt="chevron"
                            className={cn(styles.chevron, isOpen.dd2Open && styles.active)}
                            onClick={() => setIsOpen((prev) => ({ ...prev, dd2Open: !prev.dd2Open }))}
                        />
                    </div>

                    <ul className={styles.list}>
                        <li className={styles.listItem}>{t('Downloading the register of payments in manual mode or through the Excel registry')}</li>
                        <li className={styles.listItem}>{t('Automatic verification of the status of self-employed when registering payments')}</li>
                        <li className={styles.listItem}>{t('Crediting funds to a nominal account')}</li>

                        <li className={styles.listItem}>{t('Making payments with the formation of a check for each self-employed person')}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
