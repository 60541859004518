import React from 'react';
import styles from './Modal.less';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export const Modal = ({ img, modalText, title, className, closeModal }) => {
    const { t } = useTranslation();

    const faderClick = () => {
        if (closeModal) {
            closeModal();
        }
    };

    return (
        <div className={cn(styles.modal, className)}>
            <div onClick={faderClick} className={styles.fader}>
                &nbsp;
            </div>
            <div className={styles.modalContent}>
                <div className={styles.wrapper}>
                    <div className={styles.imgWrapper}>
                        <img src={img} alt="logo" />
                    </div>
                    <div className={styles.textWrapper}>
                        <h2 className={styles.title}>{t(title)}</h2>
                        <p className={styles.text}>{t(modalText)}</p>
                    </div>
                </div>
                <button onClick={closeModal} className={styles.closeBtn}></button>
            </div>
        </div>
    );
};
