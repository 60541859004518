import React, { useEffect } from 'react';
import styles from './order.less';
//import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../components/checkbox/checkbox';
import { useState } from 'react';
import orderMinus from '../../../assets/images/png/orderMinus.png';
import orderPlus from '../../../assets/images/png/orderPlus.png';
import { sendOrder } from '../../services/formServices';
import { useLocation } from 'react-router-dom';
import { ignoreMobileLocations } from '../../utils/common-helper';
import { useMobileWidth } from '../../utils/custom-hooks';
import { Button } from '../../components/button/button';

const initState = [
    {
        name: 'payment for goods/services (merchant acquiring)',
        value: false,
    },
    {
        name: 'payment for goods/services with SBP',
        value: false,
    },
    {
        name: 'payments to cards (reverse acquiring)',
        value: false,
    },
    {
        name: 'card payments with SBP',
        value: false,
    },
    {
        name: 'goods scanning',
        value: false,
    },
    {
        name: 'printing checks with sending to OFD',
        value: false,
    },
    {
        name: 'mobile communication payment',
        value: false,
    },
];

export const Order = () => {
    const { t } = useTranslation();
    const [optionsState, setOptionsState] = useState(initState);
    const [requestData, setRequestData] = useState(null);
    const [repeatOrder, setRepeatOrder] = useState(false);
    const [amount, setAmount] = useState(1);
    const location = useLocation();

    const updateState = (index) => {
        let newState = Object.assign([], optionsState);
        newState[index].value = !newState[index].value;
        setOptionsState(newState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const name = form.name.value;
        const phone = form.phone.value;
        const organization = form.organization.value;
        const options = optionsState;
        const email = form.email.value;
        try {
            // _reCaptchaRef.current.reset();
            const request = { name, phone, organization, email, options, amount };
            // setLoading(true);
            setRequestData(request);
        } catch (e) {
            console.error('error', e);
        }
    };
    const handleRepeatOrder = (e) => {
        setRepeatOrder(false);
    };

    useEffect(() => {
        if (requestData) {
            (async () => {
                try {
                    const resp = await sendOrder(requestData);
                    if (resp.status === 'Ok') {
                        // alert('Ваш запрос отправлен');
                        setRequestData(null);
                        setRepeatOrder(true);
                    } else {
                        console.log('Заказ в процессе отправки');
                    }
                } catch (err) {
                    console.log('Заказ в процессе отправки', err);
                }
                // setLoading(false);
            })();
        }
    }, [requestData]);

    const handleClickMinus = () => {
        if (amount > 1) {
            setAmount(amount - 1);
        }
    };
    const handleClickPlus = () => {
        setAmount(amount + 1);
    };
    const handleChangeAmount = (e) => {
        setAmount(Number(e.target.value));
    };

    const ignoreMobile = ignoreMobileLocations.includes(location.pathname);
    const mobileWidth = useMobileWidth() && !ignoreMobile;
    const btnVariant = mobileWidth ? 'secondary' : 'rounded';

    return (
        <section className={styles.orderPage}>
            <div className={styles.block0}></div>
            <div className={styles.block1}>
                <div className={styles.posPicWrapper}>
                    <h2 className={styles.h2}>{t('Choose your POS terminal')}</h2>
                    {!repeatOrder && (
                        <div className={styles.block2}>
                            <h3 className={styles.h3}>{t('TOPWISE T3 Smart POS')}</h3>
                            <div className={styles.optionsWrapper}>
                                <h4>{t('Options:')}</h4>
                                <div className={styles.optionsBlock}>
                                    {optionsState.map((el, index) => {
                                        return (
                                            <Checkbox
                                                value={el.value}
                                                name={el.name}
                                                className={styles.option}
                                                key={index}
                                                updateState={() => {
                                                    updateState(index);
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.countWrapper}>
                                <h4 className={styles.countHeader}>{t('Count')}</h4>
                                <img src={orderMinus} alt={'minus'} onClick={handleClickMinus} />
                                <input
                                    className={styles.inputCount}
                                    type="text"
                                    value={amount}
                                    onChange={handleChangeAmount}
                                    style={{ cursor: 'default' }}
                                ></input>
                                <img src={orderPlus} onClick={handleClickPlus} alt={'plus'} />
                            </div>

                            <form className={styles.contactsWrapper} onSubmit={handleSubmit}>
                                <input
                                    className={styles.contactsInput}
                                    style={{ width: '423px' }}
                                    placeholder={t('Your name')}
                                    type="text"
                                    id="name"
                                    maxLength={100}
                                ></input>
                                <input
                                    className={styles.contactsInput}
                                    style={{ width: '321px' }}
                                    type="phone"
                                    placeholder={t('Phone')}
                                    id="phone"
                                    maxLength={18}
                                ></input>
                                <input
                                    className={styles.contactsInput}
                                    style={{ width: '423px' }}
                                    placeholder={t('Organization name')}
                                    type="text"
                                    id="organization"
                                    maxLength={100}
                                ></input>
                                <input
                                    className={styles.contactsInput}
                                    style={{ width: '321px' }}
                                    type="email"
                                    placeholder="Email"
                                    id="email"
                                    maxLength={100}
                                ></input>
                                <div className={styles.brtContainer}>
                                    <Button variant={btnVariant} style={styles.sendButton}>
                                        {t('send')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                    {repeatOrder && (
                        <div className={styles.block3}>
                            <div className={styles.repeatEmptyBlock}></div>
                            <p className={styles.repeatText}>{t('Thank you!')}</p>
                            <p className={styles.repeatTextSmall} style={{ marginTop: '20px' }}>
                                {t('We will contact you as soon as possible')}
                            </p>
                            <div className={styles.repeatButtonContainer}>
                                <Button variant={'rounded'} style={styles.repeatButton} onClick={handleRepeatOrder}>
                                    {t('repeat order')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
