import React from 'react';
import { InternetEquiringContent } from '../../components/internetEquiringContent/internetEquiringContent';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';

export const InternetAcquiring = () => {
    return (
        <div>
            <section>
                <InternetEquiringContent />
                <ApplicationForm variant="equiring" />
            </section>
        </div>
    );
};
