export const genericErrorMessage = 'Something went wrong, please try again later.';

export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.startsWith(' ')) {
            c = c.substring(1);
        }
        if (c.startsWith(name)) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
};

export function setCookie(name, value, options) {
    options = options || {};

    let { expires } = options;

    if (typeof expires === 'number' && expires) {
        const d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires?.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = `${name}=${value}; path=/`;

    for (const propName in options) {
        updatedCookie += `; ${propName}`;
        const propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += `=${propValue}`;
        }
    }
    document.cookie = updatedCookie;
}

export function deleteCookie(name) {
    setCookie(name, '', {
        expires: -1,
    });
}

export const ignoreMobileLocations = [
    '/about',
    '/services',
    '/soyuz',
    '/fpGate',
    '/self-employed-platform',
    '/acquiring',
    '/internet-acquiring',
    '/tech-support',
    '/order-code-development',
    '/consulting',
];
