import styles from './selfEmployedHeading.less';
import { useTranslation } from 'react-i18next';

export const SelfEmployedHeading = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.blurredFrame}>
                <h1 className={styles.mainText}>{t('Self-employed platform - outsourcing exchange')}</h1>
                <p className={styles.secondaryText}>{t('Marketplace of digital solutions for the self-employed and their customers')}</p>
                <ul className={styles.featuresList}>
                    <li className={styles.feature}>
                        {t('Free access for customers to the placement of applications for the services of the Self-Employed on the online platform')}
                    </li>
                    <li className={styles.feature}>
                        {t('Service agency fee from the cost of the application taken by the Self-employed for execution')}
                    </li>
                    <li className={styles.feature}>
                        {t(
                            'Service fee for providing secure (confirmed by the Federal Tax Service and EDI) one-time or mass payments to the Self-Employed',
                        )}
                    </li>
                </ul>
            </div>
            <div className={styles.rightTextWrapper}>
                <p className={styles.rightText}>
                    {t('According to the forecast of experts, the number of registered self-employed in Russia by 2024 will grow to')}
                    <span className={styles.rightTextGreen}> 8,4 {t('MILLION OF PEOPLE ')}</span>
                    {t('and their total income will reach')}
                    <span className={styles.rightTextGreen}> 2,9 {t('TRILLION RUB.')}</span>
                </p>
            </div>
        </div>
    );
};
