import card from '../../../assets/images/png/card.png';
import equiring from '../../../assets/images/png/equiring.png';
import eEquiring from '../../../assets/images/png/eEquiring.png';
import pos from '../../../assets/images/png/pos.png';
import soyuz from '../../../assets/images/png/soyuz.png';
import suit from '../../../assets/images/png/manInSuit.png';

export const productCardsData = [
    { img: card, title: 'FP Gate Payment Gateway', to: '/fpGate/' },
    { img: pos, title: 'POS-terminal TOPWISE', to: '/pos-topwise' },
    { img: equiring, title: 'Merchant acquiring', to: '/acquiring' },
    { img: eEquiring, title: 'Internet acquiring', to: '/internet-acquiring' },
    { img: soyuz, title: '“Soyuz” mail aggregator', to: '/soyuz' },
    { img: suit, title: 'Self employed platform', to: '/self-employed-platform ' },
];
