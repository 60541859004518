import React from 'react';
import { SelfEmployedHeading } from '../../components/selfEmployedHeading/selfEmployedHeading';
import { Concept } from '../../components/concept/concept';
import { DigitalSolutions } from '../../components/digitalSolutions/digitalSolutions';
import { DdBlock } from '../../components/ddBlock/ddBlock';
import { Partners } from '../../components/partners/partners';
import { Cases } from '../../components/cases/cases';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';


export const SelfEmployedPlatform = () => {
    return (
        <div>
            <section>
                <SelfEmployedHeading />
                <Concept />
                <DigitalSolutions />
                <DdBlock />
                <Partners />
                <Cases />
                <ApplicationForm variant="cases" />
            </section>
        </div>
    );
};
