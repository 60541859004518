import React from 'react';
import { AcquiringContent } from '../../components/AcquiringContent/acquiringContent';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';


export const Acquiring = () => {
    return (
        <div>
            <section>
                <AcquiringContent />
                <ApplicationForm variant="gpay" />
            </section>
        </div>
    );
};
