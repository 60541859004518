import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/layout/layout';
import { Main } from './pages/main/main';
import { About } from './pages/about/about';
import { Contacts } from './pages/contacts/contacts';
import { Portfolio } from './pages/portfolio/portfolio';
import { ProductsPage } from './pages/productsPage/productsPage';
import { Services } from './pages/services/services';
import { Soyuz } from './pages/soyuz/soyuz';
import { FpGate } from './pages/fpGate/fpGate';
import { SelfEmployedPlatform } from './pages/selfEmployedPlatform/selfEmployedPlatform';
import { Acquiring } from './pages/acquiring/acquiring';
import { InternetAcquiring } from './pages/internetAcquiring/internetAcquiring';
import { OrderCode } from './pages/orderCode/orderCode';
import { Order } from './pages/order/order';
import './app.less';
import { TechSupport } from './pages/techSupport/techSupport';
import { Consulting } from './pages/consulting/consultingPage';

export const PATHS = {
    about: '/about',
    contacts: '/contacts',
    portfolio: '/portfolio',
    services: '/services',
    soyuz: '/soyuz',
    fpGate: '/fpGate',
    selfEmployedPlatform: '/self-employed-platform',
    acquiring: '/acquiring',
    internetAcquiring: '/internet-acquiring',
    techSupport: '/tech-support',
    topwise: '/pos-topwise',
    orderCode: '/order-code-development',
    consulting: '/consulting',
    order: '/order',
};

export const App = () => (
    <Routes>
        <Route path={'/'} element={<Layout />}>
            <Route path={'/'} element={<Main />} />
            <Route path={PATHS.about} element={<About />} />
            <Route path={PATHS.contacts} element={<Contacts />} />
            <Route path={PATHS.portfolio} element={<Portfolio />} />
            <Route path={PATHS.topwise} element={<ProductsPage />} />
            <Route path={PATHS.services} element={<Services />} />
            <Route path={PATHS.soyuz} element={<Soyuz />} />
            <Route path={PATHS.fpGate} element={<FpGate />} />
            <Route path={PATHS.selfEmployedPlatform} element={<SelfEmployedPlatform />} />
            <Route path={PATHS.acquiring} element={<Acquiring />} />
            <Route path={PATHS.internetAcquiring} element={<InternetAcquiring />} />
            <Route path={PATHS.techSupport} element={<TechSupport />} />
            <Route path={PATHS.orderCode} element={<OrderCode />} />
            <Route path={PATHS.consulting} element={<Consulting />} />
            <Route path={PATHS.order} element={<Order />} />
        </Route>
    </Routes>
);
