import React from 'react';
import styles from './checkbox.less';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import checkbox0 from '../../../assets/images/png/checkbox0.png';
import checkbox1 from '../../../assets/images/png/checkbox1.png';

export const Checkbox = ({ name, value, style, updateState, disabled }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.lineOption}>
            {!value && <img src={checkbox0} onClick={updateState} alt={'off'} />}
            {value && <img src={checkbox1} onClick={updateState} alt={'on'} />}
            <li className={cn(styles.checkbox, style)} onClick={updateState} disabled={disabled}>
                {t(name)}
            </li>
        </div>
    );
};
