import styles from './aboutWhy.less';
import { useTranslation } from 'react-i18next';
import aboutWhy1 from '../../../assets/images/png/AboutWhy1.png';
import aboutWhy2 from '../../../assets/images/png/AboutWhy2.png';
import aboutWhy3 from '../../../assets/images/png/AboutWhy3.png';

export const AboutWhy = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h1 className={styles.aboutWhyHeading}>{t('why we?')}</h1>
            <div className={styles.centerBlock}>
                <div className={styles.columnItem}>
                    <img src={aboutWhy1} alt="High Quality" width={119} height={119} />
                    <div className={styles.heading}>{t('high quality')}</div>
                    <div className={styles.text}>
                        {t(
                            'We use only modern technologies and best practices. We are responsible for what we have done. Customers come back with new ideas to us',
                        )}
                    </div>
                </div>
                <div className={styles.columnItem}>
                    <img src={aboutWhy2} alt="Unique experience" width={119} height={119} />
                    <div className={styles.heading}>{t('unique experience')}</div>
                    <div className={styles.text}>
                        {t(
                            'Our experts have trained their hands and brains on dozens of projects. We will help not only to realize the idea, but also arrange a brainstorming session',
                        )}
                    </div>
                </div>
                <div className={styles.columnItem}>
                    <img src={aboutWhy3} alt="Excellent support" width={119} height={119} />
                    <div className={styles.heading}>{t('excellent support')}</div>
                    <div className={styles.text}>{t('Always connected and proactive. You will not be left alone with the problem')}</div>
                </div>
            </div>
        </div>
    );
};
