import { Link } from 'react-router-dom';
import { CompanyCard } from '../companyCard/companyCard';
import { companiesListData } from './mainPortfolio.helper';
import styles from './mainPortfolio.less';
import { useTranslation } from 'react-i18next';

export const MainPortfolio = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1 className={styles.portfolioHeading}>{t('PORTFOLIO')}</h1>
            <ul className={styles.companyList}>
                {companiesListData.map((el, idx) => {
                    return <CompanyCard {...el} key={idx} />;
                })}
            </ul>
            <Link className={styles.textBtn} to={'./portfolio'}>
                {t('All projects')}
            </Link>
        </div>
    );
};
