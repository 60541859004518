import React, { useMemo } from 'react';
import { Navigation } from '../../entities/navigation/navigation';
import Logo from '../../../assets/images/png/logo_header.png';
import Logo_eng from '../../../assets/images/svg/logo_eng.svg';
import en from '../../../assets/images/png/en.png';
import ru from '../../../assets/images/png/ru.png';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import defaultStyles from './headerStyles.less';
import mobileStyles from './headerMobileStyles.less';
import { useMobileWidth } from '../../utils/custom-hooks';
import { ignoreMobileLocations } from '../../utils/common-helper';

export const Header = () => {
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const ignoreMobile = ignoreMobileLocations.includes(location.pathname);
    const mobileWidth = useMobileWidth() && !ignoreMobile;

    // console.log(i18n.language);
    //className={cn(s.loginString, { [s.white]: !light })}

    const styles = useMemo(() => {
        return ignoreMobile ? defaultStyles : mobileStyles;
    }, [location]);

    return (
        <header className={styles.header}>
            <div className={styles.headerFrame}>
                <div className={styles.headerNav}>
                    <NavLink to="/" className={styles.headerLogo}>
                        {i18n.language === 'ru' && (
                            <img src={Logo} alt={t('Forvard')} height={mobileWidth ? '47' : '81'} width={mobileWidth ? '86' : '151'} />
                        )}
                        {i18n.language === 'en' && (
                            <img src={Logo_eng} alt={t('Forvard')} height={mobileWidth ? '47' : '81'} width={mobileWidth ? '86' : '151'} />
                        )}
                    </NavLink>
                    <Navigation />
                </div>

                {!mobileWidth && (
                    <div className={styles.headerLanguageRight}>
                        {i18n.language === 'en' && <img src={en} alt={t('Language')} height="27" width="73" onClick={() => changeLanguage('ru')} />}
                        {i18n.language === 'ru' && <img src={ru} alt={t('Language')} height="27" width="73" onClick={() => changeLanguage('en')} />}
                    </div>
                )}
            </div>
        </header>
    );
};
