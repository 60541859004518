import { useMemo, useState } from 'react';
import defaultStyles from './formFields.less';
import mobileStyles from './formFieldsMobile.less';
import { Button } from '../button/button';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMobileWidth } from '../../utils/custom-hooks';
import { useLocation } from 'react-router-dom';
import { ignoreMobileLocations } from '../../utils/common-helper';

export const FormFields = ({ onSubmit }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isSubmitted, setIsSubmited] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmited(true);
        onSubmit(e);
    };

    const resetForm = () => {
        setIsSubmited(false);
    };

    const ignoreMobile = ignoreMobileLocations.includes(location.pathname);
    const mobileWidth = useMobileWidth() && !ignoreMobile;

    // console.log(i18n.language);
    //className={cn(s.loginString, { [s.white]: !light })}

    const styles = useMemo(() => {
        return ignoreMobile ? defaultStyles : mobileStyles;
    }, [location, ignoreMobile]);

    const btnVariant = mobileWidth ? 'secondary' : 'rounded';

    return (
        <div className={cn(styles.container, isSubmitted && styles.submitted)}>
            {isSubmitted ? (
                <div className={styles.submittedForm}>
                    <h1 className={styles.onSubmitHead}>{t('Thank you')}</h1>
                    <p className={styles.onSubmitText}>{t('We are processing your order and will contact you as soon as possible.')}</p>
                    <Button variant="long" onClick={resetForm}>
                        {t('send another request')}
                    </Button>
                </div>
            ) : (
                <form className={styles.formFields} onSubmit={handleSubmit}>
                    <h1 className={styles.formHeading}>{t('Leave a request')}</h1>
                    <input type="text" className={styles.textField} placeholder={t('Your name')} id="name" maxLength={100} />
                    <input type="phone" className={styles.textField} placeholder={t('Phone')} id="phone" maxLength={18} />
                    <textarea className={styles.textFieldBig} placeholder={t('A few words about the project...')} id="description" maxLength={2048} />
                    <div className={styles.btn}>
                        <Button variant={btnVariant}>{t('send')}</Button>
                    </div>
                </form>
            )}
        </div>
    );
};
