import React from 'react';
import styles from './consultingContent.less';
import { useTranslation } from 'react-i18next';
import phoneMan from '../.././../assets/images/png/phoneMan.png';
import greenFrame from '../.././../assets/images/png/greenFrame.png';
import loopa from '../../../assets/images/png/loopa.png';
import chart from '../../../assets/images/png/chart.png';
import ux from '../../../assets/images/png/ux.png';

export const ConsultingContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.consultingHead}>
                <div className={styles.textContainer}>
                    <h1 className={styles.heading}>{t('Consulting services')}</h1>
                    <p className={styles.text}>
                        {t(
                            'It can sometimes be very difficult to assess the current state of the IT infrastructure and draw up a plan for restructuring or introducing new services on your own by company specialists. A side view is needed.',
                        )}
                    </p>

                    <p className={styles.text}>
                        {t('We offer a comprehensive assessment -\n')}
                        <span className={styles.green}>{t('IT consulting.\n')}</span>
                        {t(
                            "Starting with identifying current and potential system errors, we will go to identifying\nand eliminating the reason for the 'non-recoupment' of investments in IT infrastructure and will be able to find a way to solve the company's strategic goals\nusing digital tools.",
                        )}
                    </p>
                    <h2 className={styles.secondaryHeading}>{t('For what?')}</h2>
                    <ul className={styles.consultingList}>
                        <li className={styles.consultingItem}>{t('Assessment and improvement of the current IT infrastructure')}</li>
                        <li className={styles.consultingItem}>
                            {t(
                                'Selection of IT solutions that are most suitable\nfor the company in terms of technical capabilities and organizational needs',
                            )}
                        </li>
                        <li className={styles.consultingItem}>
                            {t(
                                'Calculation and optimization of costs for the implementation, support and development of IT infrastructure, as well as the possibility of creating a system from scratch or its complete restructuring',
                            )}
                        </li>
                        <li className={styles.consultingItem}>
                            {t(
                                'Synchronization of IT and business processes of the company, creation of the most favorable conditions for prompt response of the IT infrastructure to changes in business processes',
                            )}
                        </li>
                        <li className={styles.consultingItem}>{t("Creation of a target model of the company's information system")}</li>
                        <li className={styles.consultingItem}>{t('Organization of an effective information technology management system')}</li>
                    </ul>
                </div>
                <div className={styles.imageContainer}>
                    <img src={phoneMan} alt="phoneMan" />
                </div>
            </div>
            <div className={styles.offer}>
                <img src={greenFrame} alt="frame" className={styles.greenFrame} />
                <h2 className={styles.secondaryHeadingBlack}>{t('We offer')}</h2>
                <ul className={styles.offerList}>
                    <li className={styles.offerListItem}>
                        <img src={loopa} alt="glass" className={styles.offerImg} />
                        <div className={styles.offerTextWrapper}>
                            <p className={styles.offerText}>{t('Audit of current software, website or mobile application')}</p>
                            <p className={styles.offerText2}>
                                {t(
                                    'we analyze your software to identify problems and deficiencies that may hinder its effectiveness and functionality. We pay attention to aspects such as performance, usability, security and more. After the audit, our experts will prepare a detailed report that will describe all the problems found and recommendations for their elimination.',
                                )}
                            </p>
                        </div>
                    </li>
                    <li className={styles.offerListItem}>
                        <img src={chart} alt="chart" className={styles.offerImg} />
                        <div className={styles.offerTextWrapper}>
                            <p className={styles.offerText}>{t('Analytics')}</p>
                            <p className={styles.offerText2}>
                                {t(
                                    'the process of collecting and analyzing data that can help identify problems and improve the performance of your software. Our experts conduct a complete analysis of your software and help identify problem areas that may affect its operation. We also provide recommendations on how to improve the performance of your software to increase its efficiency.',
                                )}
                            </p>
                        </div>
                    </li>
                    <li className={styles.offerListItem}>
                        <img src={ux} alt="ux" className={styles.offerImg} />
                        <div className={styles.offerTextWrapper}>
                            {' '}
                            <p className={styles.offerText}>{t('UX research')}</p>
                            <p className={styles.offerText2}>
                                {t(
                                    'the process of studying the user experience and behavior of your customers. Our experts conduct research to identify issues and improve the performance of your software, website or mobile app. We analyze user behavior and habits to help optimize the interface and improve the user experience.',
                                )}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};
