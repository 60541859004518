import lorus from '../../../assets/images/png/lorus.png';
import pizza from '../../../assets/images/png/pizza.png';
import vnukovo from '../../../assets/images/png/vnukovo.png';
import translon from '../../../assets/images/png/translon.png';

export const casesCardsData = [
    {
        variant: 'small',
        title: 'Lorus SCM (part of UAZ Group)',
        text: 'Geography of cooperation:\nMoscow, St. Petersburg, Yekaterinburg, Volgograd, Voronezh, Omsk, Perm\n\nNumber of self-employed: 150 people.',
        img: lorus,
        hasDetails: false,
        ignoreMobile: true,
    },
    {
        variant: 'small',
        title: 'DODO PIZZA',
        text: 'Geography of cooperation:\nChelyabinsk, Yekaterinburg\n\nNumber of self-employed: 50 people.',
        img: pizza,
        hasDetails: false,
        ignoreMobile: true,
    },
    {
        variant: 'small',
        title: 'Vnukovo International Airport',
        text: 'Geography of cooperation:\nMoscow\n\nNumber of self-employed: 70 people.',
        img: vnukovo,
        hasDetails: false,
        ignoreMobile: true,
    },
    {
        variant: 'small',
        title: 'Translon',
        text: 'Geography of cooperation:\nMoscow region, Yekaterinburg\n\nNumber of self-employed: 80 people.',
        img: translon,
        hasDetails: false,
        ignoreMobile: true,
    },
];
