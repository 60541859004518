import React from 'react';
import styles from './digitalSolutions.less';
import { useTranslation } from 'react-i18next';

export const DigitalSolutions = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{t('Implemented set of digital solutions')}</h1>
            <div className={styles.listWrapper}>
                <ul className={styles.list}>
                    <li className={styles.listItem}>{t('Search and attraction of Self-employed performers for various types of work')}</li>
                    <li className={styles.listItem}>{t('Electronic document flow between the Customer and the Self-Employed')}</li>
                    <li className={styles.listItem}>
                        {t('Remote control over the compliance of the work performed with the requirements of the Customer')}
                    </li>

                    <li className={styles.listItem}>
                        {t('Tracking the relevance of registration of the Self-Employed in the status of payers of the NAP')}
                    </li>
                </ul>
                <ul className={styles.list}>
                    <li className={styles.listItem}>{t('Individual and mass payments to the self-employed on bank cards and current accounts')}</li>
                    <li className={styles.listItem}>
                        {t('Official registration of self-employed income and receipt of registered checks from the Federal Tax Service')}
                    </li>
                    <li className={styles.listItem}>{t('Automated payment of taxes on professional income of the Self-Employed')}</li>
                </ul>
            </div>
        </div>
    );
};
