import { useEffect, useState } from 'react';

/**
 * Хук поддержки клика снаружи ref, вызывает arg.outsideClickHandler в случае если не попали по ref области.
 */

export function useOutsideAlerter(ref, outsideClickHandler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (event.offsetX > (document.documentElement.clientWidth || document.body.clientWidth)) return;
            if (ref.current && !ref.current.contains(event.target) && outsideClickHandler) {
                outsideClickHandler(event);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, outsideClickHandler]);
}

export const useMobileWidth = () => {
    const [width, setWidth] = useState(window.outerWidth);

    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.outerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return width <= 850;
};
