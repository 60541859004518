import styles from './mainServices.less';
import { useTranslation } from 'react-i18next';
import { cardsData } from './mainServices.helper';
import { SimpleBlackCard } from '../simpleBlackCard/simpleBlackCard';

export const MainServices = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h1 className={styles.servicesHeading}>{t('SERVICES')}</h1>
            <ul className={styles.cards}>
                {cardsData.map((el, idx) => {
                    return <SimpleBlackCard {...el} key={idx} />;
                })}
            </ul>
        </div>
    );
};
