import React from 'react';
import styles from './soyuzStyles.less';
import { ApplicationForm } from '../../entities/applicationForm/applicationForm';
import souzIco1 from '../../../assets/images/svg/souzIco1.svg';
import souzIco2 from '../../../assets/images/svg/souzIco2.svg';
import souzIco3 from '../../../assets/images/svg/souzIco3.svg';
import { useTranslation } from 'react-i18next';

//{t('')}

export const Soyuz = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.souzWrapper}>
            <div className={styles.block1}>
                <h1>
                    {t('New service')}
                    <br />
                    {t('for sending parcels')}
                    <br />
                    {t('to any point in Russia')}
                </h1>
                <div className={styles.greenText}>{t('AC “SOYUZ”')}</div>

                <div className={styles.descWrapper}>
                    <div className={styles.listItem}>{t('Arrange and pay for postage online')}</div>
                    <div className={styles.listItem}>
                        {t('Receive cash on delivery in a convenient way')}
                        <br />
                        {t('without visiting post offices')}
                    </div>
                </div>
            </div>

            <div className={styles.block2}>
                <div className={styles.textBlock1}>
                    <div className={styles.blockHead}>{t('Beneficial for the seller')}</div>
                    <div className={styles.listItem}>{t('Safe delivery in various ways')}</div>
                    <div className={styles.listItem}>{t('Large volume discounts')}</div>
                    <div className={styles.listItem}>{t('Easy integration with merchant automated systems using API')}</div>
                    <div className={styles.listItem}>{t('Documents are generated in the system and downloaded with one click')}</div>
                    <div className={styles.listItem}>{t('Delivery of parcels in the selected branch without queues or courier service')}</div>
                    <div className={styles.listItem}>{t('Receive cash on delivery directly in the service in ways convenient for you')}</div>
                </div>

                <div className={styles.textBlock2}>
                    <div className={styles.blockHeadBlack}>{t('Convenient for the buyer')}</div>
                    <div className={styles.listItem}>{t('Pickup points in any part of Russia')}</div>
                    <div className={styles.listItem}>{t('Delivery service')}</div>
                    <div className={styles.listItem}>{t('Payment by cash or card upon receipt')}</div>
                    <div className={styles.listItem}>{t('Easy return of goods')}</div>
                </div>
            </div>

            <div className={styles.block3}>
                <div className={styles.columnItem}>
                    <img src={souzIco1} alt="porfel" width={138} height={116} />
                    <div className={styles.descr}>{t('Private businessmen')}</div>
                </div>
                <div className={styles.columnItem}>
                    <img src={souzIco2} alt="comp" width={138} height={116} />
                    <div className={styles.descr}>{t('Internet-shops')}</div>
                </div>
                <div className={styles.columnItem}>
                    <img src={souzIco3} alt="hand" width={138} height={116} />
                    <div className={styles.descr}>{t('Active sellers on marketplaces')}</div>
                </div>
            </div>

            <div className={styles.block4}>
                <h2>{t('How it works?')}</h2>
                <div className={styles.blocksPlace}>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>1</div>
                        <div className={styles.text}>{t('Register on the site')} https://soyuz.me/</div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>2</div>
                        <div className={styles.text}>{t('Make an advance payment')}</div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>3</div>
                        <div className={styles.text}>
                            {t(
                                'Register the shipment in your personal account, indicating the weight and size characteristics and address details of the recipient',
                            )}
                        </div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>4</div>
                        <div className={styles.text}>{t('Print the generated shipping documents, stick labels on the shipping boxes')}</div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>5</div>
                        <div className={styles.text}>
                            {t('Hand over the parcels to the courier or hand them over at the selected branch of the Russian Post without a queue')}
                        </div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>6</div>
                        <div className={styles.text}>{t('Track the status of parcels in your personal account')}</div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>7</div>
                        <div className={styles.text}>{t('Wait for the parcel to be received by the recipient')}</div>
                    </div>
                    <div className={styles.numberBlock}>
                        <div className={styles.number}>8</div>
                        <div className={styles.text}>{t('Collect cash on delivery in your personal account (account or card)')}</div>
                    </div>
                </div>
            </div>

            <div className={styles.block5}>
                <ApplicationForm variant="souz" />
            </div>
        </section>
    );
};
