import styles from './aboutForvard.less';
import { useTranslation } from 'react-i18next';
import aboutHockey from '../../../assets/images/png/aboutHockey.png';

export const AboutForvard = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.leftBlock}>
                <img className={styles.aboutHockey} src={aboutHockey} alt="Forvard" />
            </div>
            <div className={styles.rightBlock}>
                <h1 className={styles.mainText}>{t('WHY FORVARD?')}</h1>
                <p className={styles.secondaryText}>
                    {t(
                        `Forward - (from the English 'forward' - striker). The main goal of the striker is to score goals. So we are attacking the development market, introducing new tactics for optimizing business processes.`,
                    )}
                </p>
            </div>
        </div>
    );
};
