import React from 'react';
import styles from './navigationStyles.less';

export const NavPolygon = () => {
    return (
        <svg className={styles.navPolygon} width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 4.5L5.59808 0H0.401924L3 4.5Z" fill="white" />
        </svg>
    );
};
