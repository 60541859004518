import React from 'react';
import styles from './button.less';
import cn from 'classnames';

export const Button = ({ variant = 'main', children, style, onClick, disabled }) => {
    return (
        <button className={cn(styles.button, styles[variant], style)} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};
